import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavigationButtonProps {
  readonly to: string;
  readonly label: string;
  readonly isMobile?: boolean;
  readonly className?: string;
}

export function NavigationButton({
  to,
  label,
  isMobile = false,
  className = '',
}: NavigationButtonProps): JSX.Element {
  return (
    <NavLink
      to={`/${to}`}
      className={`stimcar-gradient-button navigation-button ${className} ${isMobile ? 'mobile' : ''}`}
    >
      {label}
    </NavLink>
  );
}
