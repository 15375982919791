import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { SelectOption } from '@stimcar/marketplace-libs-common';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { MultiSelectState } from '../../../store/typings/store.js';

export interface MultiSelectProps<
  SD extends AnyStoreDef,
  T extends MultiSelectState,
  U extends string,
> {
  readonly $: StoreStateSelector<SD, T>;
  readonly options: readonly SelectOption<U>[];
}

export function MultiSelect<SD extends AnyStoreDef, T extends MultiSelectState, U extends string>({
  $,
  options,
}: MultiSelectProps<SD, T, U>): JSX.Element {
  const selectedOptions = useGetState($.$selectedValues);

  const onValueClickedActionCallback = useActionCallback(
    ({ actionDispatch, getState }, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      const originalSelectedValues = getState().selectedValues;
      let newSelectedValues: readonly string[];
      if (checked) {
        newSelectedValues = [...originalSelectedValues, value];
      } else {
        newSelectedValues = originalSelectedValues.filter(
          (originalValue) => originalValue !== value
        );
      }
      actionDispatch.setProperty('selectedValues', newSelectedValues);
    },
    [],
    $
  );

  return (
    <div className="multi-select">
      <div className="fixed-grid has-1-cols">
        <div className="grid">
          {options.map((option) => (
            <label key={`${option.value}-label`} className="checkbox cell mx-2">
              <input
                type="checkbox"
                value={option.value}
                key={`${option.value}-checkbox`}
                onChange={onValueClickedActionCallback}
                checked={selectedOptions.includes(option.value)}
              />
              <span className="ml-2 is-size-6-and-half">{option.label}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
