import React, { useMemo } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { SelectOption } from '@stimcar/marketplace-libs-common';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { MultiSelectWithTextState } from '../../../store/typings/store.js';
import { MultiSelect } from './MultiSelect.js';

export interface MultiSelectWithTextProps<
  SD extends AnyStoreDef,
  T extends MultiSelectWithTextState,
> {
  readonly $: StoreStateSelector<SD, T>;
  readonly filterPlaceholder: string;
}

export function MultiSelectWithText<SD extends AnyStoreDef, T extends MultiSelectWithTextState>({
  $,
  filterPlaceholder,
}: MultiSelectWithTextProps<SD, T>): JSX.Element {
  const filter = useGetState($.$filter);
  const options = useGetState($.$availableValues);

  const onFilterChangeActionCallback = useActionCallback(
    function onChangeAction({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) {
      const filterValue = e.target.value;
      actionDispatch.setProperty('filter', filterValue);
    },
    [],
    $
  );

  const filteredOptions: readonly SelectOption<string>[] = useMemo(() => {
    return options
      .filter((option) => {
        if (isTruthyAndNotEmpty(filter)) {
          return option.toLowerCase().includes(filter.toLowerCase());
        }
        return true;
      })
      .map((option) => ({ label: option, value: option }));
  }, [options, filter]);

  return (
    <>
      <div className="field">
        <p className="control has-icons-right">
          <input
            value={filter ?? ''}
            placeholder={filterPlaceholder}
            onChange={onFilterChangeActionCallback}
            className="input is-rounded is-small"
          />
          <span className="icon is-small is-right">
            <i className="fas fa-magnifying-glass" />
          </span>
        </p>
      </div>
      <MultiSelect $={$} options={filteredOptions} />
    </>
  );
}
