import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { KanbanFiltersState, MPStoreDef } from '../../../store/typings/store.js';

interface SearchBarProps {
  readonly $: StoreStateSelector<MPStoreDef, KanbanFiltersState>;
  readonly onSearchCallback: () => void;
}

export function SearchBar({ $, onSearchCallback }: SearchBarProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const textFilter = useGetState($.$textFilter);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        onSearchCallback();
      }
    },
    [onSearchCallback]
  );
  const onChangeActionCallback = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) =>
      actionDispatch.setValue(e.target.value),
    [],
    $.$textFilter
  );

  return (
    <div className="field">
      <p className="control has-icons-left">
        <input
          value={textFilter}
          onKeyDown={onKeyDown}
          className="input is-rounded"
          onChange={onChangeActionCallback}
          placeholder={t('filters.searchBarFilterPlaceholder')}
        />
        <span className="icon is-small is-left">
          <i className="fas fa-magnifying-glass" />
        </span>
      </p>
    </div>
  );
}
