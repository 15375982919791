import type { TouchEvent, TouchEventHandler } from 'react';
import React from 'react';
import type { NoArgActionCallback } from '@stimcar/libs-uikernel';
import type { MPStoreDef } from '../../store/typings/store.js';

// The required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 50;

interface UseOnSwipeReturnType<T extends Element> {
  readonly onTouchEnd: () => Promise<void>;
  readonly onTouchMove: TouchEventHandler<T>;
  readonly onTouchStart: TouchEventHandler<T>;
}

export function useOnSwipe<T extends Element>(
  onLeftSwipe: NoArgActionCallback<MPStoreDef>,
  onRightSwipe: NoArgActionCallback<MPStoreDef>
): UseOnSwipeReturnType<T> {
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);

  const onTouchStart = (event: TouchEvent<T>) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(event.targetTouches[0].clientX);
  };

  const onTouchMove = (event: TouchEvent<T>) => setTouchEnd(event.targetTouches[0].clientX);

  const onTouchEnd = async () => {
    if (touchStart && touchEnd) {
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
      const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;
      if (isLeftSwipe) {
        await onLeftSwipe();
      } else if (isRightSwipe) {
        await onRightSwipe();
      }
    }
  };

  return { onTouchStart, onTouchMove, onTouchEnd };
}
