import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState, useStateIsDefined, useWindowSizeTracker } from '@stimcar/libs-uikernel';
import { MarketplaceFrontendRoutes } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../store/typings/store.js';
import { ContactSuccessNotification } from './contact/ContactSuccessNotification.js';
import { useOnNavigation } from './hooks/useOnNavigation.js';
import './i18n.js';
import { Footer } from './layout/Footer.js';
import { NavBar } from './layout/NavBar.js';
import './scss/styles';
import { UnexpectedError } from './UnexpectedError.js';
import { ImageModalDialog } from './utils/ImageModalDialog.js';

export interface MarketplaceAppProps {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

function MarketplaceAppLayout({ $gs }: MarketplaceAppProps): JSX.Element {
  // React to navigation events
  useOnNavigation($gs);
  // Track the window size
  useWindowSizeTracker($gs);

  const hasError = useStateIsDefined($gs.$error);
  const bulmaMode = useGetState($gs.$window.$bulmaMode);
  return (
    <>
      <NavBar $gs={$gs} />
      <ContactSuccessNotification $gs={$gs} />
      <div
        className={`container is-max-widescreen mt-5 mb-2${bulmaMode === 'Mobile' || bulmaMode === 'Tablet' ? ' pl-3 pr-3' : ''}`}
      >
        {!hasError ? (
          <>
            <Outlet />
            <ImageModalDialog $={$gs.$imageModalDialog} />
          </>
        ) : (
          <UnexpectedError $gs={$gs} />
        )}
        <Footer $gs={$gs} />
      </div>
    </>
  );
}

export function MarketplaceApp({ $gs }: MarketplaceAppProps): JSX.Element {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<MarketplaceAppLayout $gs={$gs} />}>
        <Route
          path={MarketplaceFrontendRoutes.CAR_DETAILS(':companyId', ':siteId', ':kanbanId')}
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/kanban-details/KanbanDetailsPage.js'))
              .KanbanDetailsPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.CARS}
          loader={() => ({ $: $gs.$cars, $window: $gs.$window })}
          lazy={async () => ({
            Component: (await import('./pages/cars/CarsPage.js')).CarsPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.BUY}
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/buy/BuyPage.js')).BuyPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.SELL}
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/sell/SellPage.js')).SellPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.REFRESH}
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/refresh/RefreshPage.js')).RefreshPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.SITES}
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/sites/SitesPage.js')).SitesPage,
          })}
        />
        <Route
          path={MarketplaceFrontendRoutes.LEGAL_MENTIONS}
          lazy={async () => ({
            Component: (await import('./pages/legal/LegalMentionsPage.js')).LegalMentionsPage,
          })}
        />
        <Route
          path="*"
          loader={() => $gs}
          lazy={async () => ({
            Component: (await import('./pages/home/HomePage.js')).HomePage,
          })}
        />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}
