import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { MPStoreDef } from '../../store/typings/store.js';
import './ImageModalDialog.scss';
import { Image } from './image/Image.js';

function closeModalAction({ actionDispatch }: ActionContext<MPStoreDef, ImageModalDialogState>) {
  actionDispatch.setProperty('active', false);
  actionDispatch.setProperty('urlIndex', 0);
  actionDispatch.setProperty('urls', []);
}

export function openImageModaleAction(
  { actionDispatch }: ActionContext<MPStoreDef, ImageModalDialogState>,
  urls: readonly string[],
  // First image to show given by its index or its URL
  initialIndexOrUrl: number | string = 0
) {
  if (urls.length > 0) {
    // Find the initial index by URL if needed
    const initialIndex =
      typeof initialIndexOrUrl === 'string' ? urls.indexOf(initialIndexOrUrl) : initialIndexOrUrl;
    actionDispatch.setProperty('active', true);
    actionDispatch.setProperty(
      'urlIndex',
      // Prevent corner cases (index < 0 or index >= urls.length)
      initialIndex < 0 || initialIndex >= urls.length ? 0 : initialIndex
    );
    actionDispatch.setProperty('urls', urls);
  } else {
    // eslint-disable-next-line no-console
    console.error('openImageModaleAction called with no image URL to open');
  }
}

function rightButtonClickedAction({
  actionDispatch,
  getState,
}: ActionContext<MPStoreDef, ImageModalDialogState>) {
  const { urlIndex, urls } = getState();
  if (urlIndex < urls.length - 1) {
    actionDispatch.setProperty('urlIndex', urlIndex + 1);
  }
}

function leftButtonClickedAction({
  actionDispatch,
  getState,
}: ActionContext<MPStoreDef, ImageModalDialogState>) {
  const { urlIndex } = getState();
  if (urlIndex > 0) {
    actionDispatch.setProperty('urlIndex', urlIndex - 1);
  }
}

export type ImageModalDialogState = {
  readonly active: boolean;
  readonly urls: readonly string[];
  readonly urlIndex: number;
};

interface ImageModalDialogProps {
  readonly $: StoreStateSelector<MPStoreDef, ImageModalDialogState>;
}

export function ImageModalDialog({ $ }: ImageModalDialogProps): JSX.Element {
  const active = useGetState($.$active);
  const urls = useGetState($.$urls);
  const urlIndex = useGetState($.$urlIndex);
  const [t] = useTranslation('marketplace');
  const closeModalActionCallback = useActionCallback(closeModalAction, [], $);

  const rightButtonClickedActionCallback = useActionCallback(rightButtonClickedAction, [], $);

  const leftButtonClickedActionCallback = useActionCallback(leftButtonClickedAction, [], $);

  const handleUserKeyPressActionCallback = useActionCallback(
    async function handleUserKeyPressAction(
      { actionDispatch },
      { key }: KeyboardEvent
    ): Promise<void> {
      switch (key) {
        case 'Escape':
          await actionDispatch.exec(closeModalAction);
          break;
        case 'ArrowRight':
          await actionDispatch.exec(rightButtonClickedAction);
          break;
        case 'ArrowLeft':
          await actionDispatch.exec(leftButtonClickedAction);
          break;
        default:
      }
    },
    [],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('keydown', handleUserKeyPressActionCallback);

    return function cleanup(): void {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('keydown', handleUserKeyPressActionCallback);
    };
  }, [handleUserKeyPressActionCallback]);

  return (
    <div className={`modal image-modal is-fullwidth${active ? ' is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-content modal-content-container">
        <Image
          width="99%"
          height="99%"
          src={urls[urlIndex]}
          alt={t('common.imageModal.imageAlt')}
        />
      </div>
      <button
        type="button"
        className="modal-close is-large"
        onClick={closeModalActionCallback}
        aria-label={t('common.imageModal.closeModalAriaLable')}
      />
      {urlIndex > 0 && (
        <button
          type="button"
          onClick={leftButtonClickedActionCallback}
          className="button is-absolute has-no-border image-modal-left-button"
          aria-label={t('common.imageModal.previousImageAriaLabel')}
        >
          <span className="icon">
            <i className="fas fa-angle-left" />
          </span>
        </button>
      )}
      {urlIndex < urls.length - 1 && (
        <button
          type="button"
          onClick={rightButtonClickedActionCallback}
          className="button is-absolute has-no-border image-modal-right-button"
          aria-label={t('common.imageModal.nextImageAriaLabel')}
        >
          <span className="icon">
            <i className="fas fa-angle-right" />
          </span>
        </button>
      )}
    </div>
  );
}
