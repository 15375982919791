import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { ContactButton } from '../../contact/ContactButton.js';
import { WithImageBackground } from '../WithImageBackground.js';
import './WhyStimcarSection.scss';

interface WhyStimcarSectionProps extends MarketplaceAppProps {
  readonly src: string;
  readonly mode: 'buy' | 'sell';
}

export function WhyStimcarSection({ $gs, mode, src }: WhyStimcarSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <section className="why-stimcar-section mt-5">
      <WithImageBackground
        src={src}
        height="300px"
        alt={t(`${mode}.whyStimcar.alt`)}
        width={isMobile ? '350px' : '1152px'}
        childrenClassName="text-container has-text-shadow px-1"
      >
        <h3 className={`title ${isMobile ? 'is-4' : 'is-3'} mt-3 has-text-white`}>
          {t('common.whyStimcar.whyChooseStimcar')}
        </h3>
        <p className="subtitle mt-4 has-text-white">{t(`${mode}.whyStimcar.stimcarExperience`)}</p>
        <ContactButton
          $gs={$gs}
          reason={mode}
          className="stimcar-gradient-button"
          label={t('common.whyStimcar.beContacted')}
        />
      </WithImageBackground>
    </section>
  );
}
