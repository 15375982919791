import React, { useCallback } from 'react';
import type { MarketplaceStatus, SiteKey } from '@stimcar/libs-kernel';
import { MarketplaceBackendRoutes } from '@stimcar/marketplace-libs-common';
import './DetailPicture.scss';
import { Image } from './image/Image.js';
import { SoldWatermark } from './SoldWatermark.js';

export type PictureKey = SiteKey & {
  readonly kanbanId: string;
  readonly filename: string;
};

interface Props extends PictureKey {
  readonly width: string;
  readonly height: string;
  readonly loading?: 'eager' | 'lazy';
  readonly marketplaceStatus: MarketplaceStatus;
  readonly onClick?: (picture: PictureKey) => void | Promise<void>;
}

export function DetailPicture({
  width,
  height,
  onClick,
  loading = 'lazy',
  marketplaceStatus,
  ...pictureKey
}: Props): JSX.Element {
  const { companyId, siteId, kanbanId, filename } = pictureKey;
  const url = MarketplaceBackendRoutes.ATTACHMENT(companyId, siteId, kanbanId, filename);

  const onClickCallback = useCallback(async () => {
    if (onClick) {
      await onClick(pictureKey);
    }
  }, [onClick, pictureKey]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onClick !== undefined) {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onClick(pictureKey);
      }
    },
    [onClick, pictureKey]
  );

  return (
    <>
      {filename && (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <figure
            className={`image detail-picture is-4by3 is-full-height ${onClick ? ' is-clickable' : ''}`}
            onClick={onClickCallback}
            onKeyDown={onKeyDown}
          >
            <Image
              src={url}
              width={width}
              alt={filename}
              height={height}
              loading={loading}
              className={`has-fit-cover has-square-border ${marketplaceStatus === 'sold' ? 'grayed' : ''}`}
            />
            {marketplaceStatus === 'sold' ? <SoldWatermark /> : <></>}
          </figure>
        </>
      )}
    </>
  );
}
