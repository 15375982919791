import { getInitialTheme, getWindowState } from '@stimcar/libs-uikernel';
import type {
  CarsState,
  ContactModalState,
  HomeState,
  KanbanFiltersState,
  KanbanListState,
  MultiSelectState,
  MultiSelectWithTextState,
  RangePickerState,
  StoreState,
} from './typings/store.js';

export const EMPTY_MULTI_SELECT_WITH_TEXT_STATE: MultiSelectWithTextState = {
  availableValues: [],
  selectedValues: [],
  filter: undefined,
};

export const EMPTY_MULTI_SELECT_STATE: MultiSelectState = {
  selectedValues: [],
};

export const EMPTY_RANGE_FILTER: RangePickerState = {
  minValue: undefined,
  maxValue: undefined,
};

export const EMPTY_KANBAN_FILTERS_STATE: KanbanFiltersState = {
  textFilter: '',
  brandFilter: EMPTY_MULTI_SELECT_WITH_TEXT_STATE,
  fuelFilter: EMPTY_MULTI_SELECT_STATE,
  gearboxFilter: EMPTY_MULTI_SELECT_STATE,
  priceFilter: EMPTY_RANGE_FILTER,
  mileageFilter: EMPTY_RANGE_FILTER,
  yearFilter: EMPTY_RANGE_FILTER,
};

export const EMPTY_KANBAN_LIST_STATE: KanbanListState = {
  kanbans: [],
  totalResults: 0,
  isLoading: true,
};

export const EMPTY_CARS_STATE: CarsState = {
  filters: EMPTY_KANBAN_FILTERS_STATE,
  kanbanList: EMPTY_KANBAN_LIST_STATE,
  totalPages: 1,
  isMobileFilterOpened: false,
};

export const EMPTY_HOME_STATE: HomeState = {
  kanbanCarousel: {
    kanbanList: EMPTY_KANBAN_LIST_STATE,
    currentPage: 1,
    totalPages: 1,
  },
};

export const EMPTY_CONTACT_MODAL_STATE: ContactModalState = {
  active: false,
  reason: undefined,
  success: false,
  error: false,
};

export const EMPTY_STORE_STATE: StoreState = {
  home: EMPTY_HOME_STATE,
  cars: EMPTY_CARS_STATE,
  details: undefined,
  error: undefined,
  imageModalDialog: {
    active: false,
    urlIndex: 0,
    urls: [],
  },
  window: getWindowState(),
  bulmaTheme: getInitialTheme(),
  contactModal: EMPTY_CONTACT_MODAL_STATE,
  navbar: {
    isBurgerMenuActive: false,
  },
};
