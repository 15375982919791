import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector, WindowState } from '@stimcar/libs-uikernel';
import type { CarsSort } from '@stimcar/marketplace-libs-common';
import { useGetState, useScreenIsBulmaMobile, useSetCallback } from '@stimcar/libs-uikernel';
import type { CarsState, MPStoreDef } from '../../store/typings/store.js';
import { useCarsSearch } from '../hooks/useCarsSearch.js';
import { SearchBar } from '../utils/filters/SearchBar.js';
import { KanbanFilters } from './KanbanFilters.js';
import { KanbanList } from './KanbanList.js';
import { Pagination } from './Pagination.js';

interface CarsViewProps {
  readonly sort: CarsSort;
  readonly currentPage: number;
  readonly displayBottomPagination: boolean;
  readonly onClearCallback: () => void;
  readonly onSearchCallback: () => void;
  readonly $: StoreStateSelector<MPStoreDef, CarsState>;
  readonly onPageChangeCallback: (newPage: number) => void;
  readonly onSortChangeCallback: (newSort: CarsSort) => void;
}

function CarsMobileView({
  $,
  sort,
  currentPage,
  onClearCallback,
  onSearchCallback,
  onPageChangeCallback,
  onSortChangeCallback,
  displayBottomPagination,
}: CarsViewProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isLoading = useGetState($.$kanbanList.$isLoading);
  const isMobileFilterOpened = useGetState($.$isMobileFilterOpened);
  const toggleMobileFilterActionCallback = useSetCallback(
    $.$isMobileFilterOpened,
    !isMobileFilterOpened
  );
  return (
    <>
      {isMobileFilterOpened ? (
        <KanbanFilters
          $={$.$filters}
          hasSearchBar={false}
          onClearCallback={onClearCallback}
          onSearchCallback={onSearchCallback}
        />
      ) : (
        <>
          <div className="columns is-mobile mt-5">
            <div className="column is-narrow-mobile">
              <button
                type="button"
                className="stimcar-white-button"
                onClick={toggleMobileFilterActionCallback}
              >
                <span className="icon is-small is-left mr-2">
                  <i className="fas fa-filter" />
                </span>
                {t('filters.mobileButton')}
              </button>
            </div>
            <div className="column">
              <SearchBar $={$.$filters} onSearchCallback={onSearchCallback} />
            </div>
          </div>
          <Pagination
            isDisabled={isLoading}
            currentPage={currentPage}
            $totalPages={$.$totalPages}
            onPageChangeCallback={onPageChangeCallback}
          />
          <KanbanList
            isMobile
            sort={sort}
            $={$.$kanbanList}
            onSortChangeCallback={onSortChangeCallback}
          />
          {displayBottomPagination && (
            <Pagination
              isDisabled={isLoading}
              currentPage={currentPage}
              $totalPages={$.$totalPages}
              onPageChangeCallback={onPageChangeCallback}
            />
          )}
        </>
      )}
    </>
  );
}

function CarsDesktopView({
  $,
  sort,
  currentPage,
  onClearCallback,
  onSearchCallback,
  onPageChangeCallback,
  onSortChangeCallback,
  displayBottomPagination,
}: CarsViewProps): JSX.Element {
  const isLoading = useGetState($.$kanbanList.$isLoading);

  return (
    <div className="columns mt-5">
      <div className="column is-one-quarter">
        <div className="card">
          <KanbanFilters
            hasSearchBar
            $={$.$filters}
            onClearCallback={onClearCallback}
            onSearchCallback={onSearchCallback}
          />
        </div>
      </div>
      <div className="column is-three-quarters">
        <Pagination
          isDisabled={isLoading}
          currentPage={currentPage}
          $totalPages={$.$totalPages}
          onPageChangeCallback={onPageChangeCallback}
        />
        <KanbanList
          sort={sort}
          isMobile={false}
          $={$.$kanbanList}
          onSortChangeCallback={onSortChangeCallback}
        />
        {displayBottomPagination && (
          <Pagination
            isDisabled={isLoading}
            currentPage={currentPage}
            $totalPages={$.$totalPages}
            onPageChangeCallback={onPageChangeCallback}
          />
        )}
      </div>
    </div>
  );
}

interface CarsProps {
  readonly $: StoreStateSelector<MPStoreDef, CarsState>;
  readonly $window: StoreStateSelector<MPStoreDef, WindowState>;
}

export function Cars({ $, $window }: CarsProps): JSX.Element {
  const isMobile = useScreenIsBulmaMobile($window);
  const kanbanList = useGetState($.$kanbanList.$kanbans);

  const {
    sort,
    currentPage,
    onClearCallback,
    onSearchCallback,
    onPageChangeCallback,
    onSortChangeCallback,
  } = useCarsSearch($);

  const displayBottomPagination = useMemo(() => {
    return kanbanList.length > 3;
  }, [kanbanList]);

  if (isMobile) {
    return (
      <CarsMobileView
        $={$}
        sort={sort}
        currentPage={currentPage}
        onClearCallback={onClearCallback}
        onSearchCallback={onSearchCallback}
        onPageChangeCallback={onPageChangeCallback}
        onSortChangeCallback={onSortChangeCallback}
        displayBottomPagination={displayBottomPagination}
      />
    );
  }
  return (
    <CarsDesktopView
      $={$}
      sort={sort}
      currentPage={currentPage}
      onClearCallback={onClearCallback}
      onSearchCallback={onSearchCallback}
      onPageChangeCallback={onPageChangeCallback}
      onSortChangeCallback={onSortChangeCallback}
      displayBottomPagination={displayBottomPagination}
    />
  );
}
