import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CarsSort, SelectOption } from '@stimcar/marketplace-libs-common';
import { toChunks } from '@stimcar/libs-kernel';
import { useGetArrayItemIds, useGetState } from '@stimcar/libs-uikernel';
import type { KanbanListState, MPStoreDef } from '../../store/typings/store.js';
import { Select } from '../utils/filters/Select.js';
import { KanbanCard, KanbanCardSkeleton } from '../utils/kanban/KanbanCard.js';

interface KanbanListSkeletonProps {
  readonly isMobile: boolean;
}

function KanbanListSkeleton({ isMobile }: KanbanListSkeletonProps): JSX.Element {
  const columns = [...Array(3).keys()];
  return (
    <div className={`${isMobile ? 'mx-5' : ''} mt-4`}>
      <div className={`title is-skeleton ${isMobile ? '' : 'mb-3'}`}>.</div>
      <div className="columns">
        {columns.map((columnIndex) => (
          <div key={columnIndex} className="column is-one-third">
            <KanbanCardSkeleton isMobile={isMobile} />
            <KanbanCardSkeleton isMobile={isMobile} />
            <KanbanCardSkeleton isMobile={isMobile} />
          </div>
        ))}
      </div>
    </div>
  );
}

interface KanbanListViewProps {
  readonly $: StoreStateSelector<MPStoreDef, KanbanListState>;
}

function KanbanListDesktopView({ $ }: KanbanListViewProps): JSX.Element {
  const kanbanIds = useGetArrayItemIds($.$kanbans);
  const kanbanIdsChunks = useMemo(() => toChunks(kanbanIds, 3), [kanbanIds]);
  return (
    <>
      {kanbanIdsChunks.map((kanbanIdsChunck, index) => (
        <div key={kanbanIdsChunck.join(';')} className="columns">
          {kanbanIdsChunck.map((kanbanId) => (
            <div key={kanbanId} className="column is-one-third">
              <KanbanCard
                id={kanbanId}
                key={kanbanId}
                imageWidth="270px"
                imageHeight="202px"
                $kanbans={$.$kanbans}
                isInInitialViewport={index < 2}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

function KanbanListMobileView({ $ }: KanbanListViewProps): JSX.Element {
  const kanbanIds = useGetArrayItemIds($.$kanbans);
  return (
    <div className="columns m-3">
      {kanbanIds.map((kanbanId, index) => (
        <div key={kanbanId} className="column">
          <KanbanCard
            id={kanbanId}
            key={kanbanId}
            imageWidth="303px"
            imageHeight="227px"
            $kanbans={$.$kanbans}
            isInInitialViewport={index === 0}
          />
        </div>
      ))}
    </div>
  );
}

interface KanbanListProps {
  readonly sort: CarsSort;
  readonly isMobile: boolean;
  readonly onSortChangeCallback: (newSort: CarsSort) => void;
  readonly $: StoreStateSelector<MPStoreDef, KanbanListState>;
}

export function KanbanList({
  $,
  sort,
  isMobile,
  onSortChangeCallback,
}: KanbanListProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isLoading = useGetState($.$isLoading);
  const totalResults = useGetState($.$totalResults);
  const sortValues: readonly SelectOption<CarsSort>[] = useMemo(
    () => [
      {
        label: `${t('sorts.unsorted')}`,
        value: '',
      },
      {
        label: `${t('sorts.priceAsc')}`,
        value: 'price-asc',
      },
      {
        label: `${t('sorts.priceDesc')}`,
        value: 'price-desc',
      },
      {
        label: `${t('sorts.mileageAsc')}`,
        value: 'mileage-asc',
      },
      {
        label: `${t('sorts.mileageDesc')}`,
        value: 'mileage-desc',
      },
    ],
    [t]
  );

  if (isLoading) {
    return <KanbanListSkeleton isMobile={isMobile} />;
  }

  if (totalResults > 0) {
    return (
      <>
        <div className="has-text-centered my-4 is-flex is-justify-content-center is-align-items-center">
          <span className="is-size-7">
            {t('pagination.results.resultCount', { count: totalResults })}
          </span>
          <div className="ml-4">
            <Select
              options={sortValues}
              selectedOption={sort}
              ariaLabel={t('sorts.ariaLabel')}
              onChangeCallback={onSortChangeCallback}
            />
          </div>
        </div>

        {isMobile ? <KanbanListMobileView $={$} /> : <KanbanListDesktopView $={$} />}
      </>
    );
  }

  return (
    <section className="section">
      <h1 className="title">{t('pagination.results.emptyTitle')}</h1>
      <h2 className="subtitle">{t('pagination.results.emptySubtitle')}</h2>
    </section>
  );
}
