import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import type { ActionDispatch, AnyStoreDef, GetState } from '@stimcar/libs-uikernel';
import { HTTPClientImpl, Logger } from '@stimcar/libs-kernel';
import {
  BrowserKeyValueStorageImpl,
  BrowserRouterWithExternalizedHistory,
  createDispatchableStore,
  installStateConsoleHook,
} from '@stimcar/libs-uikernel';
import type { MPStoreDef } from './store/typings/store.js';
import { MarketplaceApp } from './app/App.js';
import { EMPTY_STORE_STATE } from './store/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

// Create history
const history = createBrowserHistory({ window });

export type DispatchErrorHandler<SD extends AnyStoreDef> = (
  dispatch: ActionDispatch<SD, SD['globalState']>,
  getState: GetState<SD['globalState']>,
  error: Error
) => Promise<void> | void;

// Create app store
const [, $gs, appStoreContextSetter, appStoreChangeListenerRegisterer] =
  createDispatchableStore<MPStoreDef>(EMPTY_STORE_STATE, (dispatch, getState, error) => {
    log.error('Unexpected error', error);
    dispatch.setProperty('error', 'unexpectedError');
  });

// Register state console hook
installStateConsoleHook(appStoreChangeListenerRegisterer);

// Initialize context
appStoreContextSetter({
  httpClient: new HTTPClientImpl(fetch.bind(window), (): FormData => new FormData()),
  navigate: (to) => history.push(to),
  keyValueStorage: new BrowserKeyValueStorageImpl(),
});

const root: HTMLElement | null = document.getElementById('root');
if (root === null) {
  // This is not supposed to happen, but if it ever happens,
  // we open a native popup to warn the user (otherwises
  // a simple log in the console may not be enough to
  // understand what happens)
  // eslint-disable-next-line no-alert
  window.alert('Unexpected error: DOM root not found');
} else {
  const reactRoot = ReactDOM.createRoot(root);
  reactRoot.render(
    <BrowserRouterWithExternalizedHistory history={history}>
      <MarketplaceApp $gs={$gs} />
    </BrowserRouterWithExternalizedHistory>
  );
}
