import { WAN_API_ROUTE_PREFIX } from '@stimcar/libs-kernel';
import { getQueryParams } from './utils/queryParamUtils.js';

export const DEFAULT_CARS_ITEMS_PER_PAGE = 9;
export const ELASTIC_MARKETPLACE_STATUS_KEYWORD = 'marketplaceStatus.keyword';
export const ELASTIC_INFOS_BRAND_KEYWORD = 'infos.brand.keyword';
export const ELASTIC_INFOS_MILEAGE = 'infos.mileage';
export const ELASTIC_INFOS_DATE_OF_REGISTRATION = 'infos.dateOfRegistration';
export const ELASTIC_INFOS_DATE_OF_CREATION = 'creationDate';
export const ELASTIC_ATTRIBUTES_FUEL_KEYWORD = 'attributes.fuel.keyword';
export const ELASTIC_ATTRIBUTES_FUEL = 'attributes.fuel';
export const ELASTIC_ATTRIBUTES_GEARBOX_KEYWORD = 'attributes.gearbox.keyword';
export const ELASTIC_ATTRIBUTES_GEARBOX = 'attributes.gearbox';
export const ELASTIC_ATTRIBUTES_PUBLIC_PRICE = 'attributes.marketplacePublicSellingPrice';

export const KEEP_AS_IT_IS_REFURBISHING_OPTION_PRICE = 0;
export const KEEP_AS_IT_IS_REFURBISHING_OPTION_ID = 'KEEP_AS_IT_IS_REFURBISHING_OPTION_ID';

export type CarsFilters = {
  textFilter?: string;
  brandFilter?: readonly string[];
  gearboxFilter?: readonly string[];
  fuelFilter?: readonly string[];
  minPriceFilter?: number;
  maxPriceFilter?: number;
  minMileageFilter?: number;
  maxMileageFilter?: number;
  minYearFilter?: number;
  maxYearFilter?: number;
};

export const CARS_SORTS = [
  'creationDate-asc',
  'creationDate-desc',
  'price-asc',
  'price-desc',
  'mileage-asc',
  'mileage-desc',
] as const;
export type CarsSort = (typeof CARS_SORTS)[number];

export const DEFAULT_CARS_SORTS: CarsSort = 'creationDate-desc';

export type SelectOption<T extends string> = {
  readonly value: T;
  readonly label: string;
};

export const MarketplaceFrontendRoutes = {
  HOME: '/',
  BUY: '/acheter',
  SELL: '/vendre',
  REFRESH: '/reconditionner',
  LEGAL_MENTIONS: '/mentions-legales',
  CARS: '/nos-vehicules',
  SITES: '/nos-centres',
  CAR_DETAILS: (companyId: string, siteId: string, carId: string): string =>
    `/vehicule/${companyId}/${siteId}/${carId}`,
};

export const MarketplaceBackendRoutes = {
  LIST_CARS_BRANDS: `${WAN_API_ROUTE_PREFIX}/cars/brands`,
  LIST_CARS: (offset?: number, size?: number, filters?: CarsFilters, sort?: CarsSort): string => {
    const baseUrl = `${WAN_API_ROUTE_PREFIX}/cars/list`;
    const urlSearchParams = getQueryParams(offset, size, filters, sort);
    return urlSearchParams.size > 0 ? `${baseUrl}?${urlSearchParams.toString()}` : `${baseUrl}`;
  },
  CAR: (companyId: string, siteId: string, carId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/cars/${companyId}/${siteId}/${carId}`,
  LIST_ATTACHMENTS: (companyId: string, siteId: string, carId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/list/${companyId}/${siteId}/${carId}`,
  ATTACHMENT: (companyId: string, siteId: string, carId: string, filename: string): string => {
    return `${WAN_API_ROUTE_PREFIX}/attachment/${companyId}/${siteId}/${carId}/raw/${encodeURI(filename)}`;
  },
  CAR_OPENGRAPH_PICTURE: (
    companyId: string,
    siteId: string,
    carId: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/${companyId}/${siteId}/${carId}/opengraph/${encodeURI(
      filename
    )}`,
  SEND_CONTACT_MAIL: `${WAN_API_ROUTE_PREFIX}/contact`,
};
