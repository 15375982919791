import type { PropsWithChildren } from 'react';
import React from 'react';
import { Image } from './image/Image.js';

interface WithImageBackgroundProps extends PropsWithChildren {
  readonly src: string;
  readonly alt: string;
  readonly width: string;
  readonly height: string;
  readonly className?: string;
  readonly loading?: 'eager' | 'lazy';
  readonly fetchPriority?: 'high' | 'low';
  readonly childrenClassName?: string;
}

export function WithImageBackground({
  src,
  alt,
  width,
  height,
  children,
  className = '',
  loading = 'lazy',
  fetchPriority = 'low',
  childrenClassName = '',
}: WithImageBackgroundProps): JSX.Element {
  return (
    <div className={`${className} image-background-container is-relative`}>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        fetchPriority={fetchPriority}
        className="image-background has-fit-cover"
      />
      <div className={`image-background-children-container is-absolute ${childrenClassName}`}>
        {children}
      </div>
    </div>
  );
}
