import type { MarketplaceCar } from '../typings/marketplace.js';

function toTitleCase(string: string): string {
  return string.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

export function getCarDetailsLabel(car: MarketplaceCar, includeMotor = true): string {
  const { brand, model, motor } = car;
  return `${brand.toLocaleUpperCase()} ${toTitleCase(model)}${includeMotor ? ` ${motor}` : ''}`;
}
