import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../App.js';
import { ContactButton } from '../contact/ContactButton.js';
import { B2BCustomers } from '../utils/B2BCustomers.js';
import { Image } from '../utils/image/Image.js';
import { KanbanCarousel } from '../utils/kanban/KanbanCarousel.js';
import { NavigationButton } from '../utils/NavigationButton.js';
import { WithImageBackground } from '../utils/WithImageBackground.js';
import './HomeContent.scss';

type SellContentProps = MarketplaceAppProps;

function SellSection({ $gs }: SellContentProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  return (
    <section className={`sell-section ${isMobile ? 'mobile' : ''} columns mt-5`}>
      {!isMobile && (
        <Image
          width="375px"
          height="200px"
          loading="eager"
          alt={t('home.manWithKeyAlt')}
          src="/img/home/man-with-key.webp"
          className="has-fit-cover column is-half p-0 man-with-key-image"
        />
      )}
      <div
        className={`column has-background-primary-gradient ${isMobile ? 'has-text-centered' : 'is-half description-column'}`}
      >
        <h4 className="title is-5 has-text-white mt-3">{t('home.youWantToSell')}</h4>
        {!isMobile && (
          <p className="subtitle has-text-white mt-4 is-size-6-and-half">{t('home.sellProcess')}</p>
        )}
        <ContactButton
          $gs={$gs}
          reason="sell"
          className="stimcar-white-primary-button"
          label={t('home.getRecontacted')}
        />
      </div>
    </section>
  );
}

interface RefreshSectionProps {
  readonly isMobile: boolean;
  readonly bulmaTheme: string;
}

function RefreshSection({ isMobile, bulmaTheme }: RefreshSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  return (
    <section className="hero is-mobile my-5">
      <div className="columns is-gapless ">
        <figure className="column is-one-third is-4by3">
          <Image
            height="290px"
            className="image"
            alt={t('home.manWithHammerAlt')}
            width={isMobile ? '375px' : '385px'}
            src="/img/home/man-with-hammer-2.webp"
          />
        </figure>
        <div className={`column is-flex is-align-items-center has-${bulmaTheme}-background`}>
          <div className={`${isMobile ? 'm-4' : 'ml-6 mr-6'}`}>
            <div className="has-background-primary-gradient is-inline-block">
              <h5 className="title is-4 has-text-white">{t('home.youWantToRefresh')}</h5>
            </div>
            <h5 className="title is-4 mt-1">{t('home.refreshTagline')}</h5>
            <p className="subtitle is-6 my-4">{t('home.refreshProcess')}</p>
            <NavLink to="/refresh" className="stimcar-white-primary-button">
              {t('home.knowMoreButtonLabel')}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

type HomeContentProps = MarketplaceAppProps;

export function HomeContent({ $gs }: HomeContentProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <div className="home-content">
      {isMobile ? (
        <>
          <section className="hero is-medium has-radius-normal">
            <Image
              width="350px"
              height="140px"
              loading="eager"
              fetchPriority="high"
              alt={t('home.headPictureAlt')}
              src="/img/home/home-head-image.webp"
              className="hero-body p-0 is-fullwidth-image"
            />
          </section>
        </>
      ) : (
        <section className="hero is-medium has-radius-normal">
          <WithImageBackground
            width="1152px"
            height="450px"
            loading="eager"
            fetchPriority="high"
            alt={t('home.headPictureAlt')}
            src="/img/home/home-head-image.webp"
            className="home-background hero-body has-text-shadow p-0"
          >
            <div>
              <h2 className="title home-title is-size-2 has-text-white mb-0">{t('home.title')}</h2>
              <p className="subtitle is-size-3 has-text-white has-text-weight-bold mt-3">
                {t('home.subtitle')}
              </p>
            </div>
          </WithImageBackground>
        </section>
      )}
      <section className="has-text-centered mt-5">
        {isMobile && (
          <div className="mb-5">
            <h3 className={`title ${isMobile ? 'is-6' : 'is-4'}`}>{t('home.lightSpiritVO')}</h3>
          </div>
        )}
        <div>
          <NavigationButton className="py-3" to="buy" label={t('home.buy')} isMobile={isMobile} />
          <NavigationButton
            to="sell"
            isMobile={isMobile}
            label={t('home.sell')}
            className={`py-3 my-0 ${isMobile ? ' mx-4 my-2 p-2' : 'mx-6'}`}
          />
          <NavigationButton
            to="refresh"
            className="py-3"
            isMobile={isMobile}
            label={t('home.refresh')}
          />
        </div>
        {!isMobile && (
          <div className="mt-5">
            <h3 className={`title ${isMobile ? 'is-6' : 'is-4'}`}>{t('home.lightSpiritVO')}</h3>
          </div>
        )}
      </section>
      <SellSection $gs={$gs} />
      <KanbanCarousel $={$gs.$home.$kanbanCarousel} bulmaTheme={bulmaTheme} isMobile={isMobile} />
      <RefreshSection isMobile={isMobile} bulmaTheme={bulmaTheme} />
      <B2BCustomers isMobile={isMobile} bulmaTheme={bulmaTheme} />
    </div>
  );
}
