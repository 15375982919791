import React, { useCallback } from 'react';

interface ImageProps {
  readonly src: string;
  readonly alt: string;
  readonly width: string;
  readonly height: string;
  readonly className?: string;
  readonly loading?: 'eager' | 'lazy';
  readonly fetchPriority?: 'high' | 'low';
}

export function Image({
  src,
  alt,
  height,
  width,
  className = '',
  loading = 'lazy',
  fetchPriority = 'low',
}: ImageProps): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(true);
  const onLoad = useCallback(() => setIsLoading(false), [setIsLoading]);
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      onLoad={onLoad}
      height={height}
      loading={loading}
      className={`${className}${isLoading ? ' is-skeleton' : ''}`}
      fetchPriority={fetchPriority}
      onError={({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.onerror = null; // prevents infinite loop
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = 'img/cars/car-placeholder.webp'; // fallback to a placeholder image
      }}
    />
  );
}
