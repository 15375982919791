import type { Deletable, Entity } from './kernel.js';

export type GearboxValues = '' | 'auto' | 'manual';
export type FuelValues = '' | 'diesel' | 'gas' | 'hybrid' | 'electric';

export const MKTP_SELL_PROCESS = 'SELL';
export const MKTP_BUY_PROCESS = 'BUY';

export type MarketplaceProcess = '' | typeof MKTP_BUY_PROCESS | typeof MKTP_SELL_PROCESS;

export const MKTP_OFFLINE_STATUS = 'offline';
export const MKTP_ONLINE_STATUS = 'online';
export const MKTP_SOLD_STATUS = 'sold';

export type MarketplaceStatus =
  | typeof MKTP_OFFLINE_STATUS
  | typeof MKTP_ONLINE_STATUS
  | typeof MKTP_SOLD_STATUS;

export type MarketplacePackageDeal = {
  readonly label: string;
  readonly VATRate: number;
  readonly priceWithVAT: number;
};

export type MarketplaceSaleOverview = {
  readonly salePriceWithVAT: number;
  readonly fixedFeesPackageDeals: readonly MarketplacePackageDeal[];
  readonly refurbishFeesPackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceBuyOverview = {
  readonly salePriceWithVAT: number;
  readonly deposit: number;
  readonly fixedFeesPackageDeals: readonly MarketplacePackageDeal[];
  readonly registrationFeesPackageDeals: readonly MarketplacePackageDeal[];
  readonly refurbishFeesPackageDeals: readonly MarketplacePackageDeal[];
};

export type MarketplaceOverviewRequest = {
  readonly saleOverview?: MarketplaceSaleOverview;
  readonly buyOverview?: MarketplaceBuyOverview;
};

export const MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES = {
  MARKETPLACE_LEFT_SIDE_PICTURE: { id: 'marketplaceLeftSidePicture.webp', index: 0 },
  MARKETPLACE_FRONT_VIEW_PICTURE: { id: 'marketplaceFrontViewPicture.webp', index: 1 },
  MARKETPLACE_RIGHT_SIDE_PICTURE: { id: 'marketplaceRightSidePicture.webp', index: 2 },
  MARKETPLACE_REAR_VIEW_PICTURE: { id: 'marketplaceRearViewPicture.webp', index: 3 },
} as const;

export const KANBAN_MARKETPLACE_STANDARD_PICTURES = {
  external: {
    ...MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES,
    MARKETPLACE_FRONT_LEFT_THREE_QUARTERS_VIEW_PICTURE: {
      id: 'marketplaceFrontLeftThreeQuartersViewPicture.webp',
      index: 4,
    },
    MARKETPLACE_FRONT_RIGHT_THREE_QUARTERS_VIEW_PICTURE: {
      id: 'marketplaceFrontRightThreeQuartersViewPicture.webp',
      index: 5,
    },
    MARKETPLACE_REAR_RIGHT_THREE_QUARTERS_VIEW_PICTURE: {
      id: 'marketplaceRearRightThreeQuartersViewPicture.webp',
      index: 6,
    },
    MARKETPLACE_REAR_LEFT_THREE_QUARTERS_VIEW_PICTURE: {
      id: 'marketplaceRearLeftThreeQuartersViewPicture.webp',
      index: 7,
    },
    MARKETPLACE_TRUNK_INTERIOR_PICTURE: { id: 'marketplaceTrunkInteriorPicture.webp', index: 8 },
    MARKETPLACE_ENGINE_COMPARTMENT_PICTURE: {
      id: 'marketplaceEngineCompartmentPicture.webp',
      index: 9,
    },
  },
  internal: {
    MARKETPLACE_FRONT_RIGHT_DOOR_INTERNAL_PICTURE: {
      id: 'marketplaceFrontRightDoorInternalPicture.webp',
      index: 10,
    },
    MARKETPLACE_REAR_SEAT_PICTURE: { id: 'marketplaceRearSeatPicture.webp', index: 11 },
    MARKETPLACE_COMPLETE_DASHBOARD_PICTURE: {
      id: 'marketplaceCompleteDashboardPicture.webp',
      index: 12,
    },
    MARKETPLACE_STEERING_WHEEL_AND_METER_PICTURE: {
      id: 'marketplaceSteeringWheelAndMeterPicture.webp',
      index: 13,
    },
    MARKETPLACE_GEARBOX_PICTURE: { id: 'marketplaceGearboxPicture.webp', index: 14 },
    MARKETPLACE_CENTRAL_DASHBOARD_PICTURE: {
      id: 'marketplaceCentralDashboardPicture.webp',
      index: 15,
    },
    MARKETPLACE_CONTROL_SCREEN_GPS_PICTURE: {
      id: 'marketplaceControlScreenGpsPicture.webp',
      index: 16,
    },
  },
} as const;

export type MarketplaceStandardInternalPicture =
  (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['internal'][keyof (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['internal']]['id'];
export type MarketplaceStandardExternalPicture =
  (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['external'][keyof (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['external']]['id'];
export type MarketplaceStandardExternalMainPicture =
  (typeof MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES)[keyof typeof MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES]['id'];

export type RefurbishingOption = Entity &
  Deletable & {
    readonly label: string;
    readonly packageDealId: string;
    readonly index: number;
  };

export type MarketplaceStandardPictureAttribute = {
  readonly id: string;
  readonly index: number;
};

export const DEFECT_MARK_OF_USE = 'defectMarkOfUse';
export const DEFECT_FRICTION = 'defectFriction';
export const DEFECT_SCRATCH = 'defectScratch';
export const DEFECT_PAINT_CHIP = 'defectPaintChip';
export const DEFECT_IMPACT = 'defectImpact';
export const DEFECT_BODYWORK_DENT = 'defectBodyDent';
export const DEFECT_PAINT_DAMAGE = 'defectPaintDamage';
export const DEFECT_INTERIOR_SCRATCH = 'defectInteriorScratch';
export const DEFECT_TEAR = 'defectTear';
export const DEFECT_MISSING_OR_BROKEN_PART = 'defectMissingOrBrokenPart';

export const DEFECT_TYPES = [
  DEFECT_MARK_OF_USE,
  DEFECT_FRICTION,
  DEFECT_SCRATCH,
  DEFECT_PAINT_CHIP,
  DEFECT_IMPACT,
  DEFECT_BODYWORK_DENT,
  DEFECT_PAINT_DAMAGE,
  DEFECT_INTERIOR_SCRATCH,
  DEFECT_TEAR,
  DEFECT_MISSING_OR_BROKEN_PART,
] as const;

export type DefectType = (typeof DEFECT_TYPES)[number];

export type PositionOnCar = {
  readonly pictureId: MarketplaceStandardExternalMainPicture | null;
  readonly x: number | null;
  readonly y: number | null;
};

export type Defect = Entity &
  Deletable & {
    readonly type: DefectType;
    readonly label: string;
    readonly picture: string;
    readonly positionOnCar: PositionOnCar | null;
    readonly refurbishingOptions: readonly RefurbishingOption[];
    readonly index: number;
  };
