import React, { useMemo } from 'react';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { MarketplaceKanbanSummary } from '@stimcar/marketplace-libs-common';
import { useActionCallback, useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import { getKanbanDetailsLabel } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../../../store/typings/store.js';
import { DetailPicture } from '../DetailPicture.js';
import { formatCompanyId, formatNumberWithSpaces } from '../format/formatUtils.js';

function gotoKanbanDetailsPageAction({
  navigate,
  getState,
}: ActionContext<MPStoreDef, MarketplaceKanbanSummary>): void {
  const { id } = getState();
  const [companyId, siteId, kanbanId] = id.split('/');
  navigate(`/cars/details/${companyId}/${siteId}/${kanbanId}`);
}

interface KanbanCardSkeletonProps {
  readonly isMobile: boolean;
}

export function KanbanCardSkeleton({ isMobile }: KanbanCardSkeletonProps): JSX.Element {
  return (
    <div className="card kanban-card">
      <div
        className="card-image is-skeleton"
        style={{ height: isMobile ? '227px' : '202px', borderRadius: 0 }}
      />
      <div className="card-content">
        <div className="skeleton-lines py-4">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

interface KanbanCardProps {
  readonly id: string;
  readonly imageWidth: string;
  readonly imageHeight: string;
  readonly isInInitialViewport?: boolean;
  readonly $kanbans: StoreStateSelector<MPStoreDef, readonly MarketplaceKanbanSummary[]>;
}

export function KanbanCard({
  id,
  $kanbans,
  imageWidth,
  imageHeight,
  isInInitialViewport = false,
}: KanbanCardProps): JSX.Element {
  const $ = useArrayItemSelector($kanbans, id);
  const kanban = useGetState($);
  const [companyId, siteId, kanbanId] = useMemo(() => id.split('/'), [id]);
  const publicPrice = useGetState($.$publicPrice);
  const mileage = useGetState($.$mileage);
  const dateOfRegistration = useGetState($.$dateOfRegistration);
  const status = useGetState($.$status);
  const mainPicture = useGetState($.$pictures.$main);

  const openKanbanDetailsActionCallback = useActionCallback(gotoKanbanDetailsPageAction, [], $);
  const openKeyDownCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        await actionDispatch.exec(gotoKanbanDetailsPageAction);
      }
    },
    [],
    $
  );

  // The date of registration may be unset (null or undefined)
  const year = useMemo(
    () => (dateOfRegistration ? new Date(dateOfRegistration).getFullYear() : undefined),
    [dateOfRegistration]
  );

  const labelText = getKanbanDetailsLabel(kanban, false);
  const locationAndYearText = `${formatCompanyId(companyId)} - ${year}`;
  const mileageText = `${formatNumberWithSpaces(mileage)} km`;
  const publicPriceText = `${formatNumberWithSpaces(publicPrice)} €`;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={openKeyDownCallback}
      onClick={openKanbanDetailsActionCallback}
      className="card has-square-border is-clickable"
    >
      <div className="card-image">
        <DetailPicture
          siteId={siteId}
          width={imageWidth}
          kanbanId={kanbanId}
          height={imageHeight}
          companyId={companyId}
          filename={mainPicture}
          marketplaceStatus={status}
          loading={isInInitialViewport ? 'eager' : 'lazy'}
        />
      </div>
      <div className="card-content has-text-centered is-narrow">
        <div className="content">
          <p className="title is-5 has-text-ellipsis mb-2">{labelText}</p>
          <p className="subtitle is-6 mb-0">{locationAndYearText}</p>
          <p className="subtitle is-6 mb-2">{mileageText}</p>
          <p className="stimcar-gradient-button is-6">{publicPriceText}</p>
        </div>
      </div>
    </div>
  );
}
