import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { ContactButton } from '../../contact/ContactButton.js';
import { WithImageBackground } from '../WithImageBackground.js';
import './HeadSection.scss';

interface HeadSectionProps extends MarketplaceAppProps {
  readonly className?: string;
  readonly mode: 'buy' | 'sell' | 'refresh';
}

export function HeadSection({ $gs, mode, className = '' }: HeadSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  return (
    <WithImageBackground
      loading="eager"
      fetchPriority="high"
      alt={t(`${mode}.headPictureAlt`)}
      src={`/img/${mode}/head-image.webp`}
      height={isMobile ? '180px' : '450px'}
      width={isMobile ? '350px' : '1152px'}
      className={`head-section${isMobile ? ' mobile' : ''} ${className}`}
    >
      {!isMobile && (
        <ContactButton
          $gs={$gs}
          reason={mode}
          label={t('common.title')}
          className={`stimcar-gradient-button is-5 ${isMobile ? 'my-2' : 'mt-6'}`}
        />
      )}
      <div className="columns">
        <div className={`column ${isMobile ? 'is-four-fifths-mobile my-2' : 'is-two-thirds my-4'}`}>
          <h2 className={`title has-text-weight-bold ${isMobile ? 'is-3' : 'is-2'}`}>
            {t(`${mode}.${isMobile ? 'shortSubtitle' : 'subtitle'}`)}
          </h2>
        </div>
      </div>
      {!isMobile && (
        <div className="has-text-centered">
          <ContactButton
            $gs={$gs}
            reason={mode}
            label={t(`${mode}.headContactLabel`)}
            className="stimcar-square-light-button mt-6 px-5 py-3"
          />
        </div>
      )}
    </WithImageBackground>
  );
}
