import React from 'react';
import { useTranslation } from 'react-i18next';

export function LegalMentions(): JSX.Element {
  const [t] = useTranslation('legalMentions');
  return (
    <>
      <h1 className="title is-1">{t('title')}</h1>
      <ul>
        <li className="mb-5">
          <h2 className="title is-4">{t('legalInformations.title')}</h2>
          <p className="mb-3">{t('legalInformations.firstParagraph')}</p>
          <p className="mb-3">
            <b>{t('legalInformations.secondParagraphTitle')}</b>
            {t('legalInformations.secondParagraph')}
          </p>
          <p className="mb-3">
            <b>{t('legalInformations.thirdParagraphTitle')}</b>
            {t('legalInformations.thirdParagraph')}
          </p>
          <p className="mb-3">
            <b>{t('legalInformations.fourthParagraphTitle')}</b>
            {t('legalInformations.fourthParagraph')}
          </p>
          <p className="mb-3">
            <b>{t('legalInformations.fifthParagraphTitle')}</b>
            {t('legalInformations.fifthParagraph')}
          </p>
          <p className="mb-3">
            <b>{t('legalInformations.sixthParagraphTitle')}</b>
            {t('legalInformations.sixthParagraph')}
          </p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('generalConditions.title')}</h2>
          <p className="mb-3">{t('generalConditions.firstParagraph')}</p>
          <p className="mb-3">{t('generalConditions.secondParagraph')}</p>
          <p className="mb-3">{t('generalConditions.thirdParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('services.title')}</h2>
          <p className="mb-3">{t('services.firstParagraph')}</p>
          <p className="mb-3">{t('services.secondParagraph')}</p>
          <p className="mb-3">{t('services.thirdParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('contractLimitations.title')}</h2>
          <p className="mb-3">{t('contractLimitations.firstParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('intellectualProperty.title')}</h2>
          <p className="mb-3">{t('intellectualProperty.firstParagraph')}</p>
          <p className="mb-3">{t('intellectualProperty.secondParagraph')}</p>
          <p className="mb-3">{t('intellectualProperty.thirdParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('responsabilityLimitations.title')}</h2>
          <p className="mb-3">{t('responsabilityLimitations.firstParagraph')}</p>
          <p className="mb-3">{t('responsabilityLimitations.secondParagraph')}</p>
          <p className="mb-3">{t('responsabilityLimitations.thirdParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('personalData.title')}</h2>
          <p className="mb-3">{t('personalData.firstParagraph')}</p>
          <p className="mb-3">{t('personalData.secondParagraph')}</p>
          <p className="mb-3">{t('personalData.thirdParagraph')}</p>
          <p className="mb-3">{t('personalData.fourthParagraph')}</p>
          <p className="mb-3">{t('personalData.fifthParagraph')}</p>
          <p className="mb-3">{t('personalData.sixthParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('linksAndCookies.title')}</h2>
          <p className="mb-3">{t('linksAndCookies.firstParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.secondParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.thirdParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.fourthParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.fifthParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.sixthParagraph')}</p>
          <p className="mb-3">{t('linksAndCookies.seventhParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('rightAndJuridiction.title')}</h2>
          <p className="mb-3">{t('rightAndJuridiction.firstParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('concernedLaws.title')}</h2>
          <p className="mb-3">{t('concernedLaws.firstParagraph')}</p>
        </li>
        <li className="mb-5">
          <h2 className="title is-4">{t('glossary.title')}</h2>
          <p className="mb-3">{t('glossary.firstParagraph')}</p>
          <p className="mb-3">{t('glossary.secondParagraph')}</p>
        </li>
      </ul>
    </>
  );
}
