import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../App.js';
import { ExperienceSection } from '../utils/experience/ExperienceSection.js';
import { HeadSection } from '../utils/head-section/HeadSection.js';
import { KanbanCarousel } from '../utils/kanban/KanbanCarousel.js';
import { ServicesSection } from '../utils/services/ServicesSection.js';
import { StimerSection } from '../utils/stimers/StimersSection.js';
import { WhyStimcarSection } from '../utils/why-stimcar/WhyStimcarSection.js';

type BuyContentProps = MarketplaceAppProps;

const MODE = 'buy';

export function BuyContent({ $gs }: BuyContentProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <>
      <HeadSection $gs={$gs} mode={MODE} />
      <StimerSection $gs={$gs} mode={MODE} />
      <ExperienceSection $gs={$gs} mode={MODE} alt={t('buy.stimcarExperienceAlt')} />
      <KanbanCarousel $={$gs.$home.$kanbanCarousel} bulmaTheme={bulmaTheme} isMobile={isMobile} />
      <WhyStimcarSection $gs={$gs} src="/img/buy/car-and-persons.webp" mode={MODE} />
      <section className="my-5 py-5">
        <h4 className="title is-4 has-text-centered">{t('buy.stimersHandleEverything')}</h4>
      </section>
      <ServicesSection $gs={$gs} mode={MODE} />
    </>
  );
}
