import React from 'react';
import { useTranslation } from 'react-i18next';
import './B2BCustomers.scss';
import { Image } from './image/Image.js';
import { WithImageBackground } from './WithImageBackground.js';

const B2B_CUSTOMERS = [
  'peugeot',
  'citroen',
  'stellantis',
  'vw',
  'audi',
  'avis',
  'sixt',
  'ford',
  'enterprise',
  'hertz',
];

interface CustomerLogoProps {
  readonly id: string;
  readonly isMobile: boolean;
}

function CustomerLogo({ id, isMobile }: CustomerLogoProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <div className="card cell m-2 has-background-white">
      <figure className="image is-4by3 m-4">
        <Image
          width={isMobile ? '73px' : '150px'}
          height={isMobile ? '55px' : '115px'}
          className="customer-logo"
          src={`/img/logos/${id}.svg`}
          alt={t('b2bCustomer.companyLogo', { company: id })}
        />
      </figure>
    </div>
  );
}

interface B2BCustomersProps {
  readonly bulmaTheme: string;
  readonly isMobile: boolean;
}

export function B2BCustomers({ bulmaTheme, isMobile }: B2BCustomersProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <section className="b2b-customers-section hero">
      <WithImageBackground
        height="120px"
        alt={t('common.patternAlt')}
        width={isMobile ? '350px' : '1152px'}
        childrenClassName="is-absolute-centered"
        src={`/img/background/pattern-${bulmaTheme}.svg`}
      >
        <div className="title is-5 m-6">{t('b2bCustomer.trust')}</div>
      </WithImageBackground>
      <div className="fixed-grid has-5-cols has-2-cols-mobile my-4 mx-6">
        <div className="grid">
          {B2B_CUSTOMERS.map((id) => (
            <CustomerLogo key={`grid-${id}`} id={id} isMobile={isMobile} />
          ))}
        </div>
      </div>
    </section>
  );
}
