import React, { useMemo } from 'react';
import type { MarketplaceContactReason } from '@stimcar/marketplace-libs-common';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { ContactButton } from '../../contact/ContactButton.js';
import { VerticalCard } from '../VerticalCard.js';

export type CardContent = {
  readonly picture: string;
  readonly alt: string;
  readonly title: string;
  readonly subtitle?: string;
};

interface ContactAndCardsProps extends MarketplaceAppProps {
  readonly cardTitle?: string;
  readonly cardSubtitle?: string;
  readonly contactTitle: string;
  readonly contactSubtitle: string;
  readonly cardDesktopWidth?: number;
  readonly contactButtonLabel: string;
  readonly cards: readonly CardContent[];
  readonly contactReason: MarketplaceContactReason;
}

export function ContactAndCards({
  $gs,
  cards,
  cardTitle,
  cardSubtitle,
  contactTitle,
  contactReason,
  contactSubtitle,
  contactButtonLabel,
  cardDesktopWidth = 170,
}: ContactAndCardsProps): JSX.Element {
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const cardContainerStyle = useMemo(
    () => (isMobile ? {} : { width: cardDesktopWidth }),
    [isMobile, cardDesktopWidth]
  );

  const isEvenNumberOfCards = useMemo(() => cards.length % 2 === 0, [cards]);

  return (
    <div className={`fixed-grid ${isMobile ? 'has-1-cols-mobile mt-5' : 'has-2-cols'}`}>
      <div className="grid is-gapless">
        <div className="cell is-flex is-align-items-center has-background-primary-gradient">
          <div className={`${isMobile ? 'm-2' : 'm-4'}`}>
            <h3 className={`title is-4 has-text-white ${isMobile ? 'm-2' : 'm-4'}`}>
              {contactTitle}
            </h3>
            <h4
              className={`subtitle is-6 has-text-white ${isMobile ? 'm-2 is-size-6-and-half' : 'm-4'}`}
            >
              {contactSubtitle}
            </h4>
            <ContactButton
              $gs={$gs}
              reason={contactReason}
              label={contactButtonLabel}
              className={`stimcar-white-primary-button ${isMobile ? 'm-2' : 'm-4'}`}
            />
          </div>
        </div>
        <div
          className={`cell has-${bulmaTheme}-background ${isMobile ? 'mt-5' : 'is-flex is-align-items-center'}`}
        >
          <div className={`${isMobile ? 'm-2' : 'm-5'}`}>
            {cardTitle && <h4 className="title is-4 mb-2">{cardTitle}</h4>}
            {cardSubtitle && <h5 className="is-6 my-2">{cardSubtitle}</h5>}
            <div
              className={`columns${isMobile ? ` is-mobile is-flex-wrap-${isEvenNumberOfCards ? 'wrap' : 'wrap-reverse is-justify-content-center'}` : ''}`}
            >
              {cards.map(({ picture, alt, title, subtitle }) => (
                <div
                  key={`card-${alt}`}
                  style={cardContainerStyle}
                  className={`column ${isMobile ? 'is-half' : 'is-one-third'} `}
                >
                  <VerticalCard
                    alt={alt}
                    title={title}
                    picture={picture}
                    subtitle={subtitle}
                    isMobile={isMobile}
                    subtitleClassName="subtitle is-6"
                    titleClassName="title is-6 kanban-card-ellipsis has-text-weight-bold"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
