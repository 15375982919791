import React, { useCallback, useMemo } from 'react';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { AnyStoreDef, StoreStateSelector } from '../../zustand/index.js';
import { useActionCallback, useGetState } from '../../zustand/zustand-hooks.js';
import './PictureSelector.scss';

export type PositionOnPicture = {
  readonly pictureId: string | null;
  readonly x: number | null;
  readonly y: number | null;
};

interface MarkerOnPictureComponentProps<SD extends AnyStoreDef> {
  readonly children: JSX.Element;
  readonly $selectedPictureId: StoreStateSelector<SD, string>;
  readonly positionOnPicture: PositionOnPicture;
}

export function MarkerOnPictureComponent<SD extends AnyStoreDef>({
  children,
  $selectedPictureId,
  positionOnPicture,
}: MarkerOnPictureComponentProps<SD>): JSX.Element {
  const selectedPictureId = useGetState($selectedPictureId);

  const hasKnownPosition =
    isTruthy(positionOnPicture.pictureId) &&
    isTruthy(positionOnPicture.x) &&
    isTruthy(positionOnPicture.y);
  const isOnSelectedPicture = selectedPictureId === positionOnPicture.pictureId;

  const shouldBeDisplayed = hasKnownPosition && isOnSelectedPicture;

  const styleForPosition = { left: `${positionOnPicture.x}%`, top: `${positionOnPicture.y}%` };

  if (!shouldBeDisplayed) {
    return <></>;
  }

  return (
    <div style={styleForPosition} className="bullet-marker">
      {children}
    </div>
  );
}

interface PictureSelectionComponentProps {
  readonly id: string;
  readonly selected: boolean;
  readonly isMobile?: boolean;
  readonly additionalLayerOnButtonClassname?: string;
  readonly getIconSrcFromPictureId: (pictureId: string) => string;
  readonly selectPictureCallback: (pictureId: string) => Promise<void>;
  readonly disablePictureSelectionCallback?: (pictureId: string) => boolean;
}

function PictureSelectionComponent({
  id,
  selected,
  isMobile,
  selectPictureCallback,
  getIconSrcFromPictureId,
  disablePictureSelectionCallback,
  additionalLayerOnButtonClassname,
}: PictureSelectionComponentProps): JSX.Element {
  const isDisabled = useMemo(() => {
    if (isTruthy(disablePictureSelectionCallback)) {
      return disablePictureSelectionCallback(id);
    }
    return false;
  }, [disablePictureSelectionCallback, id]);

  const selectionCallback = useCallback(async (): Promise<void> => {
    await selectPictureCallback(id);
  }, [selectPictureCallback, id]);

  return (
    <div className="picture-selector-icons-container is-relative is-inline-block">
      <button
        type="button"
        disabled={isDisabled}
        onClick={selectionCallback}
        className={`button button-icon p-1${selected ? ' selected has-background-primary' : ''}`}
      >
        <img
          alt={id}
          draggable={false}
          src={getIconSrcFromPictureId(id)}
          className={`picture-selector-icon${isMobile ? '-mobile' : ''}`}
        />
      </button>
      {isTruthy(additionalLayerOnButtonClassname) && (
        <div className={additionalLayerOnButtonClassname} />
      )}
    </div>
  );
}

interface PictureSelectorProps<SD extends AnyStoreDef> {
  readonly children: JSX.Element;
  readonly isMobile?: boolean;
  readonly picturesIds: readonly string[];
  readonly $selectedPictureId: StoreStateSelector<SD, string>;
  readonly getIconSrcFromPictureId: (pictureId: string) => string;
  readonly getPictureSrcFromPictureId: (pictureId: string) => string;
  readonly disablePictureSelectionCallback?: (pictureId: string) => boolean;
  readonly onPositionSelectionCallback?: (positionOnPicture: PositionOnPicture) => Promise<void>;
  readonly getAdditionalLayerOnButtonClassnameCallback?: (
    selectedPictureId: string
  ) => string | undefined;
}

export function PictureSelector<SD extends AnyStoreDef>({
  children,
  isMobile,
  picturesIds,
  $selectedPictureId,
  getIconSrcFromPictureId,
  getPictureSrcFromPictureId,
  onPositionSelectionCallback,
  disablePictureSelectionCallback,
  getAdditionalLayerOnButtonClassnameCallback,
}: PictureSelectorProps<SD>): JSX.Element {
  const selectedPictureId = useGetState($selectedPictureId);

  const onPicSelectionCallback = useActionCallback(
    ({ actionDispatch }, pictureId: string) => {
      actionDispatch.setValue(pictureId);
    },
    [],
    $selectedPictureId
  );

  const isClickable = isTruthy(onPositionSelectionCallback);

  const handleImageClick = isClickable
    ? async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const image = event.currentTarget;
        const { width, height, left, top } = image.getBoundingClientRect();

        // Compute coordinates in percentage to be independant from image size
        const xPercent = parseFloat((((event.clientX - left) / width) * 100).toFixed(2));
        const yPercent = parseFloat((((event.clientY - top) / height) * 100).toFixed(2));

        await onPositionSelectionCallback({
          pictureId: selectedPictureId,
          x: xPercent,
          y: yPercent,
        });
      }
    : undefined;

  const picturesIdsWithClassname = picturesIds.reduce<Record<string, string>>((acc, pictureId) => {
    if (isTruthy(getAdditionalLayerOnButtonClassnameCallback)) {
      const className = getAdditionalLayerOnButtonClassnameCallback(pictureId);
      if (isTruthy(className)) {
        acc[pictureId] = className;
      }
    }
    return acc;
  }, {});

  const pictureSrc = isTruthyAndNotEmpty(selectedPictureId)
    ? getPictureSrcFromPictureId(selectedPictureId)
    : null;

  return (
    <div className="picture-selector mr-1">
      <div className="is-relative is-inline-block">
        {isTruthy(pictureSrc) && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            src={pictureSrc}
            alt={selectedPictureId}
            onClick={handleImageClick}
            onKeyDown={() => {}}
            draggable={false}
            className={`picture-selector-image target${isClickable ? ' clickable-image' : ''}`}
          />
        )}
        {children}
      </div>
      <div className="buttons is-centered">
        {picturesIds.map((id) => (
          <div key={id}>
            <PictureSelectionComponent
              key={id}
              id={id}
              isMobile={isMobile}
              selected={id === selectedPictureId}
              selectPictureCallback={onPicSelectionCallback}
              getIconSrcFromPictureId={getIconSrcFromPictureId}
              additionalLayerOnButtonClassname={picturesIdsWithClassname[id]}
              disablePictureSelectionCallback={disablePictureSelectionCallback}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
