import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  DefectWithSelectedRefurbishingOptions,
  MarketplaceCar,
} from '@stimcar/marketplace-libs-common';
import { getCarDetailsLabel } from '@stimcar/marketplace-libs-common';
import { formatPrice } from '../utils/format/formatUtils.js';
import { getTotalRefurbishingPrice } from './utils/defectsUtils.js';

interface SummaryTableRowProps {
  readonly label: string;
  readonly price: number;
  readonly narrowSidePadding?: boolean;
  readonly hasBackgroundGradient?: boolean;
}

function SummaryTableRow({
  label,
  price,
  narrowSidePadding,
  hasBackgroundGradient,
}: SummaryTableRowProps): JSX.Element {
  const formattedPrice = useMemo(() => formatPrice(price, 2), [price]);
  return (
    <tr className={hasBackgroundGradient ? 'has-background-primary-gradient' : ''}>
      <th
        className={`has-no-border${hasBackgroundGradient ? ' has-text-white' : ''}${narrowSidePadding ? ' pl-1' : ''}`}
      >
        {label}
      </th>
      <th
        className={`has-no-border has-text-right${hasBackgroundGradient ? ' has-text-white' : ''}${narrowSidePadding ? ' pr-1' : ''}`}
      >
        {formattedPrice}
      </th>
    </tr>
  );
}

interface SummaryTableProps {
  readonly car: MarketplaceCar;
  readonly displayCarLabel?: boolean;
  readonly narrowSidePadding?: boolean;
  readonly defectsWithSelectedRefurbishingOptions: readonly DefectWithSelectedRefurbishingOptions[];
}

export function SummaryTable({
  car,
  displayCarLabel,
  narrowSidePadding,
  defectsWithSelectedRefurbishingOptions,
}: SummaryTableProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const totalRefurbishingPrice = useMemo(
    () => getTotalRefurbishingPrice(car.defects, defectsWithSelectedRefurbishingOptions),
    [defectsWithSelectedRefurbishingOptions, car.defects]
  );
  return (
    <table className="table is-fullwidth">
      <tfoot>
        <SummaryTableRow
          price={car.publicPrice}
          narrowSidePadding={narrowSidePadding}
          label={displayCarLabel ? getCarDetailsLabel(car) : t('defects.car')}
        />
        <SummaryTableRow
          narrowSidePadding={narrowSidePadding}
          price={totalRefurbishingPrice}
          label={t('defects.refurbishing')}
        />
        <SummaryTableRow
          hasBackgroundGradient
          label={t('defects.total')}
          narrowSidePadding={narrowSidePadding}
          price={car.publicPrice + totalRefurbishingPrice}
        />
      </tfoot>
    </table>
  );
}
