import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export function formatNumberWithSpaces(number: number): string {
  return new Intl.NumberFormat('fr-FR').format(number);
}

export function formatCompanyId(companyId: string): string {
  if (isTruthyAndNotEmpty(companyId)) {
    if (companyId === 'crnm1') {
      return 'Nantes';
    }
    return companyId.charAt(0).toUpperCase() + companyId.slice(1);
  }
  return '';
}
