import React from 'react';
import { useTranslation } from 'react-i18next';
import './SoldWatermark.scss';

export function SoldWatermark(): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <div className="sold-watermark is-absolute">
      {/* we use a SVG to allow the text to resize dynamically according to the container size */}
      <svg width="100%" height="100%" viewBox="0 0 500 200" preserveAspectRatio="xMinYMin meet">
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="90px"
          fontWeight="bold"
          fill="white"
          opacity={0.9}
        >
          {t('watermark.sold')}
        </text>
      </svg>
    </div>
  );
}
