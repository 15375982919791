import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import { LightDarkThemeSelector, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { MarketplaceFrontendRoutes } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../../store/typings/store.js';
import { ContactButton } from '../contact/ContactButton.js';
import { Image } from '../utils/image/Image.js';
import './NavBar.scss';

interface NavBarLinkProps {
  readonly id: string;
  readonly to: string;
  readonly $: StoreStateSelector<MPStoreDef, boolean>;
}

function NavBarLink({ id, to, $ }: NavBarLinkProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const closeBurgerMenuCallback = useSetCallback($, false);
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `navbar-item${isActive ? ' is-active has-text-weight-bold has-text-dark has-light-background' : ''}`
      }
      onClick={closeBurgerMenuCallback}
    >
      {t(`navBar.${id}`).toLocaleUpperCase()}
    </NavLink>
  );
}

interface NavBarProps {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

export function NavBar({ $gs }: NavBarProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isBurgerMenuActive = useGetState($gs.$navbar.$isBurgerMenuActive);

  const toggleBurgerMenuCallback = useSetCallback(
    $gs.$navbar.$isBurgerMenuActive,
    !isBurgerMenuActive
  );

  return (
    <nav
      role="navigation"
      className="navbar marketplace-navbar is-fixed-top py-1"
      aria-label={t('navBar.navBarAriaLabel')}
    >
      <div className="container is-max-widescreen">
        <div className="navbar-brand">
          <NavLink
            to={MarketplaceFrontendRoutes.HOME}
            className="navbar-item has-background-inherit"
          >
            <Image
              height="50px"
              width="180px"
              loading="eager"
              fetchPriority="high"
              className="stimcar-logo"
              alt={t('common.stimcarLogoAlt')}
              src={`stimcarlogo-${bulmaTheme}.svg`}
            />
          </NavLink>
          <button
            type="button"
            aria-expanded="false"
            className="navbar-burger"
            data-target="navbarItems"
            onClick={toggleBurgerMenuCallback}
            aria-label={t('navBar.menuAriaLabel')}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>
        <div id="navbarItems" className={`navbar-menu ${isBurgerMenuActive ? ' is-active' : ''}`}>
          <div className="navbar-end">
            <NavBarLink
              id="buy"
              $={$gs.$navbar.$isBurgerMenuActive}
              to={MarketplaceFrontendRoutes.BUY}
            />
            <NavBarLink
              id="sell"
              $={$gs.$navbar.$isBurgerMenuActive}
              to={MarketplaceFrontendRoutes.SELL}
            />
            <NavBarLink
              id="refresh"
              $={$gs.$navbar.$isBurgerMenuActive}
              to={MarketplaceFrontendRoutes.REFRESH}
            />
            <NavBarLink
              id="cars"
              $={$gs.$navbar.$isBurgerMenuActive}
              to={MarketplaceFrontendRoutes.CARS}
            />
            <NavBarLink
              id="sites"
              $={$gs.$navbar.$isBurgerMenuActive}
              to={MarketplaceFrontendRoutes.SITES}
            />
            <div className="navbar-item">
              <ContactButton
                $gs={$gs}
                reason="request"
                label={t('navBar.contactUs')}
                className="stimcar-grey-button"
              />
            </div>
            <div className="navbar-item">
              <LightDarkThemeSelector $gs={$gs} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
