import type { StoreApi, UseBoundStore } from 'zustand';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { ArrayItems, DeepPartial } from '@stimcar/libs-kernel';
import {
  applyPayload,
  computePayload,
  ensureError,
  ensureSequentialMethodCalls,
  isTruthy,
  Logger,
  nonnull,
} from '@stimcar/libs-kernel';
import type {
  Action,
  ActionArgs,
  ActionCallback,
  ActionContext,
  ActionDispatch,
  AnyStoreDef,
  ArrayItemStateType,
  Dispatch,
  GetState,
  GlobalDispatch,
  GlobalStoreStateSelector,
  NoArgActionCallback,
  ObjectStateType,
  Reducer,
  ReducerArgs,
  SetState,
  State,
  StoreStateSelector,
} from './typings/zustand-dispatch.js';
import { DispatchWithChangeTriggerImpl } from './zustand-dispatch-with-change-trigger.js';

/* eslint-disable @typescript-eslint/no-explicit-any */

const log: Logger = Logger.new(import.meta.url);

interface GlobalState<SD extends AnyStoreDef> {
  readonly dispatch: Dispatch<SD, SD['globalState']>;
  readonly state: SD['globalState'];
}

type UseStore<SD extends AnyStoreDef> = UseBoundStore<StoreApi<GlobalState<SD>>>;

export type StateChangeListener<S extends State> = (newState: S) => void;

export type StateChangeListenerRegisterer<S extends State> = (
  listener: StateChangeListener<S>
) => void;

export type DispatchableContextSetter<C extends object> = (ctx: Partial<C>) => void;

export type DispatchErrorHandler<SD extends AnyStoreDef> = (
  dispatch: ActionDispatch<SD, SD['globalState']>,
  getState: GetState<SD['globalState']>,
  error: Error
) => Promise<void> | void;

type ErrorHandler = (error: Error) => Promise<void>;

const NOOP_ERROR_HANDLER = async <SD extends AnyStoreDef>(
  dispatch: ActionDispatch<SD, SD['globalState']>,
  getState: GetState<SD['globalState']>,
  error: Error
  // eslint-disable-next-line @typescript-eslint/require-await
): Promise<void> => {
  throw error;
};

function scopeStateAccessors<S extends ObjectStateType, K extends keyof S>(
  setState: SetState<S>,
  getState: GetState<S>,
  key: K
): [SetState<S[K]>, GetState<S[K]>] {
  const scopedGetState: GetState<S[K]> = (): S[K] => {
    return getState()[key];
  };
  const scopedSetState: SetState<S[K]> = (partial: Partial<S[K]>): void => {
    const actualState = getState();
    const newState = {
      ...actualState,
      [key]: partial,
    };
    setState(newState);
  };
  return [scopedSetState, scopedGetState];
}

function scopeArrayItemsStateAccessors<S extends readonly ArrayItemStateType[]>(
  setState: SetState<S>,
  getState: GetState<S>,
  id: string
): [SetState<ArrayItems<S>>, GetState<ArrayItems<S>>] {
  const scopedGetState: GetState<ArrayItems<S>> = (): ArrayItems<S> => {
    const array = getState();
    const arrayItem = array.find((item) => typeof item === 'object' && item.id === id);
    if (!arrayItem) {
      throw Error(`Array does not contain any item identified by '${id}'`);
    }
    return arrayItem as any;
  };
  const scopedSetState: SetState<ArrayItems<S>> = (partial: Partial<ArrayItems<S>>): void => {
    const array = getState();
    setState(array.map((item) => (item.id === id ? { ...item, ...partial, id } : item)) as any);
  };
  return [scopedSetState, scopedGetState];
}

function createActionDispatch<SD extends AnyStoreDef, S extends State>(
  path: string,
  rootDispatch: ActionDispatch<SD, SD['globalState']> | undefined,
  setState: SetState<S>,
  getState: GetState<S>,
  getGlobalState: GetState<SD['globalState']>,
  actionContextProvider: () => SD['actionContext']
): ActionDispatch<SD, S> {
  /**
   * Dispatches must be stable in order not to let react perform useless renders.
   * That is why, all created dispatches are saved in a cache in order to reuse
   * it.
   */
  const cachedDispatches: any = {};
  const actionDispatch: any = {
    path,
    applyPayload: (
      payload: undefined | DeepPartial<S> | ((state: S) => undefined | DeepPartial<S>)
    ): void => {
      const thePayload = typeof payload === 'function' ? payload(getState()) : payload;
      // Update the state only if the payload is not empty
      if (
        thePayload !== undefined &&
        ((Array.isArray(thePayload) && thePayload.length > 0) ||
          Reflect.ownKeys(thePayload).length > 0)
      ) {
        setState(applyPayload(getState() as any, thePayload));
      }
    },
    reduce: <R extends Reducer<State>>(reducer: R, ...args: ReducerArgs<State, R>): void => {
      const newArgs: any[] = [getState()];
      args.forEach((arg: any): void => {
        newArgs.push(arg);
      });
      setState(Reflect.apply(reducer, reducer, newArgs) as S);
    },
    setProperty: <K extends keyof S>(key: K, value: S[K]): void => {
      const actualState = getState();
      if (!isTruthy(actualState)) {
        throw new Error();
      }
      if (Reflect.get(actualState as ObjectStateType, key) !== value) {
        const newState = {
          ...(actualState as ObjectStateType),
          [key]: value,
        };
        setState(newState as S);
      }
    },
    setValue: (value: S): void => {
      // If state does not change, do nothing
      if (getState() === value) {
        return;
      }
      switch (typeof value) {
        // Primitive types : simply overwrite the state value
        case 'bigint':
        case 'boolean':
        case 'number':
        case 'string':
        case 'undefined':
          setState(value);
          break;
        case 'object':
          // Null value : simply overwrite the state value
          if (value === null) {
            setState(value);
          } else if (Array.isArray(value)) {
            // Array value : simply overwrite the state value
            setState(value);
          } else {
            /** Object value : the update strategy depends on the actual value */
            const actualValue = getState();
            // If not set, simply overwrite the state value
            if (actualValue === undefined || actualValue === null) {
              setState(value);
            } else if (typeof actualValue !== 'object') {
              // If the actual value has not the same type, simply overwrite the state value
              setState(value);
            } else if (Array.isArray(actualValue)) {
              // If the actual value is an array, simply overwrite the state value
              setState(value);
            } else {
              // Otherwise, use a reducer to save existing fields (instead of replacing
              // the whole object)
              setState({
                ...(getState() as object),
                ...value,
              });
            }
          }
          break;
        case 'function':
        case 'symbol':
        default:
          throw new Error(`Unexpected attempt to store a '${typeof value}' in the state`);
      }
    },
    exec: async <A extends Action<SD, S>>(action: A, ...args: ActionArgs<A>): Promise<void> => {
      if (Object.keys(action).includes('toGlobalAction')) {
        throw Error(
          'You might have called exec with a callback as parameter function. Try to use execCallback'
        );
      }
      if (!Array.isArray(args)) {
        throw Error('Unexpected args type');
      }
      /*
       * Prepare the action arguments
       */
      const ctx = {
        ...actionContextProvider(),
        actionDispatch,
        globalActionDispatch: !rootDispatch ? actionDispatch : rootDispatch,
        getState,
        getGlobalState,
        setState,
      };
      const newArgs: any[] = [];
      newArgs.push(ctx);
      args.forEach((arg: any): void => {
        newArgs.push(arg);
      });
      /*
       * Call the action
       */
      await Reflect.apply(action, action, newArgs);
    },
    scopeProperty: <K extends keyof S>(key: K): ActionDispatch<SD, State> => {
      const cacheKey = `scope/${key as string}`;
      let result: ActionDispatch<SD, State> = cachedDispatches[cacheKey];
      if (!result) {
        const [scopedSetState, scopedGetState] = scopeStateAccessors(
          setState as SetState<ObjectStateType>,
          getState as GetState<ObjectStateType>,
          key as string
        );
        result = createActionDispatch(
          `${path}.$${String(key)}`,
          (!rootDispatch ? actionDispatch : rootDispatch) as ActionDispatch<SD, State>,
          scopedSetState,
          scopedGetState,
          getGlobalState,
          actionContextProvider
        );
        cachedDispatches[cacheKey] = result;
      }
      return result;
    },
    // Warning : unlike standard scoped dispatch (for state fields), a dispatch that is
    // scoped on an array item cannot be cached as it depends on the identifier of
    // the object, which by definition, is not static.
    // Caching these dispatches would lead to a memory leak.
    scopeArrayItem: (id: string): ActionDispatch<SD, State> => {
      const [scopedSetState, scopedGetState] = scopeArrayItemsStateAccessors(
        setState as any,
        getState as any,
        id
      );
      // This cannot be cached (id is dynamic, not static like other field names)
      return createActionDispatch(
        `${path}[${id}]`,
        (!rootDispatch ? actionDispatch : rootDispatch) as ActionDispatch<SD, State>,
        scopedSetState,
        scopedGetState,
        getGlobalState,
        actionContextProvider
      ) as any;
    },
    // Warning : unlike standard scoped dispatch (for state fields), a dispatch that is
    // scoped on a record item cannot be cached as it depends on the key of
    // the entry, which by definition, is not static.
    // Caching these dispatches would lead to a memory leak.
    scopeRecordItem: (key: string): ActionDispatch<SD, State> => {
      const [scopedSetState, scopedGetState] = scopeStateAccessors(
        setState as any,
        getState as any,
        key
      );
      // This cannot be cached (id is dynamic, not static like other field names)
      return createActionDispatch(
        `${path}.$${key}`,
        (!rootDispatch ? actionDispatch : rootDispatch) as ActionDispatch<SD, State>,
        scopedSetState,
        scopedGetState,
        getGlobalState,
        actionContextProvider
      ) as any;
    },
    execCallback: async <A extends Action<SD, State>>(
      callback: ActionCallback<SD, A>,
      ...args: ActionArgs<A>
    ): Promise<void> => {
      const globalDispatch = (!rootDispatch ? actionDispatch : rootDispatch) as ActionDispatch<
        SD,
        SD['globalState']
      >;
      await globalDispatch.exec(callback.toGlobalAction(), ...(args as any));
    },
  };
  return actionDispatch as ActionDispatch<SD, S>;
}

/**
 * Logs the state update.
 * @param zustandGetState the zustand getState function.
 * @param partial the partial state that will be applied on the previous state.
 */
// FIXME should be disabled in production
function logStateUpdate<S extends State>(initial: S, target: S): void {
  try {
    // Try to log the payload
    log.debug('stateChangePayload', computePayload(initial as any, target));
  } catch (e) {
    // Log the full state otherwise
    log.debug(
      'setState:',
      target,
      '(',
      "Logging full state because I couldn't compute the state update payload : ",
      ensureError(e).message,
      ')'
    );
  }
}

type SyncOrAsyncFunction = (...args: any) => Promise<void> | void;

type ToAsyncFunction<AC extends SyncOrAsyncFunction> = AC extends (
  ...args: any[]
) => Promise<void> | void
  ? (...args: Parameters<AC>) => Promise<void>
  : never;

type AsyncCallWrapper = <C extends SyncOrAsyncFunction>(
  callable: C,
  ...args: Parameters<C>
) => Promise<void>;

/**
 * This component will help to prevent state update actions from being
 * executed concurrently.
 */
function createStateTransactionManagerWrapper(
  flushState: () => void,
  rollbackState: () => void,
  errorHandler: ErrorHandler
): AsyncCallWrapper {
  const synchronizer = {
    synchronize: async <C extends SyncOrAsyncFunction>(
      callable: C,
      ...args: Parameters<C>
    ): Promise<void> => {
      try {
        await Reflect.apply(callable, callable, args);
        flushState();
      } catch (e) {
        rollbackState();
        await errorHandler(ensureError(e));
      }
    },
  };
  return ensureSequentialMethodCalls(synchronizer, 0).synchronize;
}

/**
 * Ensure a function will be synchronized according to the given synchronizer.
 * @param stateTransactionCallWrapper the synchronizer to use.
 * @param thisArgument the object to bind the method to.
 * @param callable the callable to synchronize.
 */
function manageStateTransaction<AC extends SyncOrAsyncFunction>(
  stateTransactionCallWrapper: AsyncCallWrapper,
  thisArgument: object,
  callable: AC
): ToAsyncFunction<AC> {
  const result = async (...args: Parameters<AC>): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newArgs: any[] = [];
    newArgs.push(callable);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args.forEach((arg: any): void => {
      newArgs.push(arg);
    });
    await Reflect.apply(stateTransactionCallWrapper, thisArgument, newArgs);
  };
  return result as ToAsyncFunction<AC>;
}

let actionCallbackExecCount = 0;

function createPublicDispatch<SD extends AnyStoreDef, S extends State>(
  path: string,
  rootActionDispatch: ActionDispatch<SD, SD['globalState']> | undefined,
  selector:
    | ((rootActionDispatch: ActionDispatch<SD, SD['globalState']>) => ActionDispatch<SD, S>)
    | undefined,
  setState: SetState<S>,
  getState: GetState<S>,
  getGlobalState: GetState<SD['globalState']>,
  actionContextProvider: () => SD['actionContext'],
  stateTransactionManager: AsyncCallWrapper
): [Dispatch<SD, S>, ActionDispatch<SD, S>] {
  const actionDispatch = createActionDispatch(
    path,
    rootActionDispatch,
    setState,
    getState,
    getGlobalState,
    actionContextProvider
  );

  /**
   * Dispatches must be stable in order not to let react perform useless renders.
   * That is why, all created dispatches are saved in a cache in order to reuse
   * it.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cachedFieldDispatches: any = {};
  const publicDispatch = {
    path,
    applyPayload: manageStateTransaction(
      stateTransactionManager,
      actionDispatch,
      actionDispatch.applyPayload
    ),
    // @ts-ignore
    reduce: manageStateTransaction(stateTransactionManager, actionDispatch, actionDispatch.reduce),
    setProperty: manageStateTransaction(
      stateTransactionManager,
      actionDispatch,
      actionDispatch.setProperty
    ),
    setValue: manageStateTransaction(
      stateTransactionManager,
      actionDispatch,
      actionDispatch.setValue
    ),
    exec: manageStateTransaction(stateTransactionManager, actionDispatch, actionDispatch.exec),
    execCallback: manageStateTransaction(
      stateTransactionManager,
      actionDispatch,
      actionDispatch.execCallback
    ),
    createActionCallback: <A extends Action<SD, S>>(action: A): ActionCallback<SD, A> => {
      const callback = async (...args: ActionArgs<A>): Promise<void> => {
        const executionKey = `execActionCallback:${
          action.name === '' ? 'anonymousAction' : action.name
        }#${actionCallbackExecCount}`;
        actionCallbackExecCount += 1;
        const start = Date.now();
        try {
          log.info(`${executionKey}(`, args.length > 0 ? args : '', ')');
          await actionDispatch.exec(action, ...args);
        } finally {
          log.info(`${executionKey}()`, 'took', Date.now() - start, 'ms');
        }
      };
      // The callback must be synchronized
      const synchronizedCallback = manageStateTransaction(
        stateTransactionManager,
        publicDispatch,
        callback
      );
      // And merged with toGlobalAction method
      const result = Object.assign(synchronizedCallback, {
        toGlobalAction: (): ((
          ctx: ActionContext<SD, SD['globalState']>,
          ...args: ActionArgs<A>
        ) => Promise<void>) => {
          return async (
            { actionDispatch: localActionDispatch }: any,
            ...args: ActionArgs<A>
          ): Promise<void> => {
            const actionDispatch = (
              !selector ? localActionDispatch : selector(localActionDispatch)
            ) as ActionDispatch<SD, S>;
            log.info(
              `-> execNestedActionCallback:${
                action.name === '' ? 'anonymousAction' : action.name
              }(`,
              args.length > 0 ? args : '',
              ')'
            );
            return await actionDispatch.exec(action, ...args);
          };
        },
      });
      return result;
    },
    scopeProperty: <K extends keyof S>(key: K): Dispatch<SD, State> => {
      const cacheKey = `scope/${key as string}`;
      let result = cachedFieldDispatches[cacheKey];
      if (!result) {
        const [scopedSetState, scopedGetState] = scopeStateAccessors(
          setState as SetState<ObjectStateType>,
          getState as GetState<ObjectStateType>,
          key as string
        );
        // eslint-disable-next-line prefer-destructuring
        result = createPublicDispatch(
          `${path}.$${String(key)}`,
          !rootActionDispatch ? actionDispatch : (rootActionDispatch as any),
          (rootDispatch: ActionDispatch<SD, SD['globalState']>): ActionDispatch<SD, any> => {
            return !selector
              ? rootDispatch.scopeProperty(key as any)
              : selector(rootDispatch).scopeProperty(key);
          },
          scopedSetState,
          scopedGetState,
          getGlobalState,
          actionContextProvider,
          stateTransactionManager
        )[0];
        cachedFieldDispatches[cacheKey] = result;
      }
      return result;
    },
    // Warning : unlike standard scoped dispatch (for state fields), a dispatch that is
    // scoped on an array item cannot be cached as it depends on the identifier of
    // the object, which by definition, is not static.
    // Caching these dispatches would lead to a memory leak.
    scopeArrayItem: (id: string): Dispatch<SD, State> => {
      const [scopedSetState, scopedGetState] = scopeArrayItemsStateAccessors(
        setState as any,
        getState as any,
        id
      );
      // This cannot be cached (id is dynamic, not static like other field names)
      return createPublicDispatch(
        `${path}[${id}]`,
        !rootActionDispatch ? actionDispatch : (rootActionDispatch as any),
        (rootDispatch: ActionDispatch<SD, SD['globalState']>): ActionDispatch<SD, any> => {
          return !selector
            ? rootDispatch.scopeArrayItem(id)
            : selector(rootDispatch).scopeArrayItem(id);
        },
        scopedSetState,
        scopedGetState,
        getGlobalState,
        actionContextProvider,
        stateTransactionManager
      )[0] as any;
    },
    // Warning : unlike standard scoped dispatch (for state fields), a dispatch that is
    // scoped on a record item cannot be cached as it depends on the key of
    // the entry, which by definition, is not static.
    // Caching these dispatches would lead to a memory leak.
    scopeRecordItem: (key: string): Dispatch<SD, State> => {
      const [scopedSetState, scopedGetState] = scopeStateAccessors(
        setState as any,
        getState as any,
        key
      );
      // This cannot be cached (id is dynamic, not static like other field names)
      return createPublicDispatch(
        `${path}.$${key}`,
        !rootActionDispatch ? actionDispatch : (rootActionDispatch as any),
        (rootDispatch: ActionDispatch<SD, SD['globalState']>): ActionDispatch<SD, any> => {
          return !selector
            ? rootDispatch.scopeRecordItem(key)
            : selector(rootDispatch).scopeRecordItem(key);
        },
        scopedSetState,
        scopedGetState,
        getGlobalState,
        actionContextProvider,
        stateTransactionManager
      )[0] as any;
    },
  };
  return [publicDispatch as unknown as Dispatch<SD, S>, actionDispatch];
}

// Magic property to retrieve the proxy handler from the proxy instance
const PROXY_HANDLER_PROPERTY = '@proxyHandler';
const NEW_ARRAY_ITEM_SELECTOR_PROPERTY = '@newArrayItemSelector';
const NEW_RECORD_ITEM_SELECTOR_PROPERTY = '@newRecordItemSelector';
const NEW_SELECTOR_WITH_CHANGE_TRIGGER_PROPERTY = '@newSelectorWithChangeTrigger';

export interface InternalStateSelectorInfos<SD extends AnyStoreDef, S> {
  readonly useStoreHook: UseStore<SD>;

  readonly dispatch: [S] extends [State] ? Dispatch<SD, S> : never;

  readonly parent: StoreStateSelector<SD, any> | undefined;

  readonly pathKeys: readonly string[];

  readonly selectStateFromGlobalState: (globalState: SD['globalState']) => S;
}

export const extractInternalSelectorInfos = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>
): InternalStateSelectorInfos<SD, S> => {
  const proxyHandler = Reflect.get(
    $,
    PROXY_HANDLER_PROPERTY
  ) as unknown as InternalStateSelectorInfos<SD, S>;
  if (!proxyHandler) {
    throw new Error(`Failed to retrieve internal selector from ${$.path}`);
  }
  return proxyHandler;
};

export const newArrayItemSelector = <SD extends AnyStoreDef, S extends ArrayItemStateType>(
  $: StoreStateSelector<SD, readonly S[]>,
  id: string
): StoreStateSelector<SD, S> => {
  const selectorCreator = Reflect.get($, NEW_ARRAY_ITEM_SELECTOR_PROPERTY) as unknown as (
    id: string
  ) => StoreStateSelector<SD, S>;
  return selectorCreator(id);
};

export const newRecordItemSelector = <SD extends AnyStoreDef, S extends State, K extends keyof any>(
  $: StoreStateSelector<SD, Record<K, S>>,
  key: K
): StoreStateSelector<SD, S> => {
  const selectorCreator = Reflect.get($, NEW_RECORD_ITEM_SELECTOR_PROPERTY) as unknown as (
    key: K
  ) => StoreStateSelector<SD, S>;
  return selectorCreator(key);
};

export const newSelectorWithChangeTrigger = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>,
  onChangeActionCallback: NoArgActionCallback<SD>
): StoreStateSelector<SD, S> => {
  const selectorCreator = Reflect.get($, NEW_SELECTOR_WITH_CHANGE_TRIGGER_PROPERTY) as unknown as (
    triggerActionCallback: NoArgActionCallback<SD>
  ) => StoreStateSelector<SD, S>;
  return selectorCreator(onChangeActionCallback);
};

const UNDEFINED_VALUE_CHAINING = {} as Error;

const NULL_VALUE_CHAINING = {} as Error;

/**
 * The state selector is an object that mime
 */
class StateSelectorProxyHandler<SD extends AnyStoreDef>
  implements ProxyHandler<StoreStateSelector<SD, any>>, InternalStateSelectorInfos<SD, any>
{
  private readonly children = new Map();

  public readonly useStoreHook: UseStore<SD>;

  public readonly dispatch: Dispatch<SD, any>;

  public readonly root: StoreStateSelector<SD, SD['globalState']>;

  public readonly parent: StoreStateSelector<SD, any> | undefined;

  public readonly key: string | undefined;

  public readonly path: string;

  public readonly pathKeys: readonly string[];

  public readonly stateSelector: (parentState: any) => any;

  constructor(
    useZustandStoreHook: UseStore<SD>,
    dispatch: Dispatch<SD, any>,
    root?: StoreStateSelector<SD, SD['globalState']>,
    parent?: StoreStateSelector<SD, any>,
    key?: string,
    stateSelector?: (parentState: any) => any
  ) {
    this.useStoreHook = useZustandStoreHook;
    this.dispatch = dispatch;
    this.root = (!root ? this : root) as any;
    this.parent = parent;
    this.key = key;
    const parentHandler = (parent ? extractInternalSelectorInfos(parent) : undefined) as
      | StateSelectorProxyHandler<SD>
      | undefined;
    this.stateSelector = !stateSelector ? (state) => state : stateSelector;
    this.pathKeys = parentHandler
      ? [...parentHandler.pathKeys, nonnull(key, `state selector key is unexpectedly null`)]
      : [];
    this.path = parentHandler
      ? `${parentHandler.path}.${key?.endsWith('()') ? '' : '$'}${key}`
      : '$';
  }

  private getOrCreateChild(
    cacheKey: string,
    newChildProps: () => {
      readonly childDispatch: Dispatch<SD, any>;
      readonly childStateSelector: (parentState: any) => any;
      readonly parent: StoreStateSelector<SD, any>;
    }
  ) {
    const child = this.children.get(cacheKey);
    if (child) {
      return child;
    }

    // If key is missing from the cache, create the item
    const { childDispatch, childStateSelector, parent } = newChildProps();
    const newChild = new Proxy(
      {} as any,
      new StateSelectorProxyHandler<SD>(
        this.useStoreHook,
        childDispatch,
        this.root,
        parent,
        cacheKey,
        childStateSelector
      )
    );
    // Register the new child in the cache
    this.children.set(cacheKey, newChild);
    return newChild;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  public get(target: StoreStateSelector<SD, any>, p: string | symbol, receiver: any): any {
    const pStr = String(p);
    switch (pStr) {
      // If the property equals the magic property, return the proxy handler itself
      case PROXY_HANDLER_PROPERTY:
        return this;
      case '$root':
        return this.key;
      case 'key':
        return this.key;
      case 'path':
        return this.path;
      case 'select':
        return (gs: any) => this.selectStateFromGlobalState(gs);
      case 'asDefined':
        return () =>
          this.getOrCreateChild('asDefined()', () => ({
            childDispatch: this.dispatch,
            childStateSelector: (state) => {
              if (state === undefined || state === null) {
                throw Error(
                  `Trying to chain a state that is expected to be defined: ${this.path} (actual value: ${state})`
                );
              }
              return state;
            },
            parent: receiver,
          }));
      case 'optChaining':
        return () =>
          this.getOrCreateChild('optChaining()', () => ({
            childDispatch: this.dispatch,
            childStateSelector: (state) => {
              if (state === undefined) {
                throw UNDEFINED_VALUE_CHAINING;
              }
              if (state === null) {
                throw NULL_VALUE_CHAINING;
              }
              return state;
            },
            parent: receiver,
          }));
      case NEW_ARRAY_ITEM_SELECTOR_PROPERTY:
        return (id: string) =>
          // This is a child selector for array items. Unlike other child selector
          // this one cannot be cached (because array identifiers are not static
          // like interface field names)
          new Proxy(
            {} as any,
            new StateSelectorProxyHandler<SD>(
              this.useStoreHook,
              this.dispatch.scopeArrayItem(id),
              this.root,
              receiver,
              id,
              (state) => {
                return (state as readonly ArrayItemStateType[]).find((item) => item.id === id);
              }
            )
          );
      case NEW_RECORD_ITEM_SELECTOR_PROPERTY:
        return (key: string) =>
          // This is a child selector for record items. Unlike other child selector
          // this one cannot be cached (because record keys are not static
          // like interface field names)
          new Proxy(
            {} as any,
            new StateSelectorProxyHandler<SD>(
              this.useStoreHook,
              this.dispatch.scopeRecordItem(key),
              this.root,
              receiver,
              String(key),
              (state) => {
                return state[key];
              }
            )
          );
      case NEW_SELECTOR_WITH_CHANGE_TRIGGER_PROPERTY:
        return (triggerActionCallback: NoArgActionCallback<SD>) =>
          // This is a derived selector with change trigger. Unlike other child selector
          // this one cannot be cached (because change trigger is not static)
          new Proxy(
            {} as any,
            this.key !== undefined
              ? /* non root case */ new StateSelectorProxyHandler<SD>(
                  this.useStoreHook,
                  new DispatchWithChangeTriggerImpl(this.dispatch, triggerActionCallback),
                  this.root,
                  this.parent,
                  this.key,
                  this.stateSelector
                )
              : /* root case */ new StateSelectorProxyHandler<SD>(
                  this.useStoreHook,
                  new DispatchWithChangeTriggerImpl(this.dispatch, triggerActionCallback)
                )
          );
      default:
        if (pStr.startsWith('$')) {
          // Query children cache
          const key = String(p).substring(1); // Remove trailing $
          return this.getOrCreateChild(key, () => ({
            childDispatch: this.dispatch.scopeProperty(key),
            childStateSelector: (state) => {
              if (state === undefined || state === null) {
                throw new Error(
                  `Trying to read a property (${pStr}) of ${
                    state === undefined ? 'an undefined' : 'a null'
                  } state (${this.path})`
                );
              }
              return state[key];
            },
            parent: receiver,
          }));
        }
        // Otherwise rely on the proxied object
        return Reflect.get(target, p);
    }
  }

  public set(): boolean {
    throw Error('Readonly object');
  }

  /**
   * Selects the state from the local state.
   * If there is an optional chaining with an undefined value, the process
   * is interrupted.
   * @param globalState the global state.
   * @returns the selected state.
   */
  protected doSelectStateFromGlobalState(globalState: any): any {
    if (!this.parent) {
      return globalState;
    }
    const parentHandler = extractInternalSelectorInfos(
      this.parent
    ) as StateSelectorProxyHandler<SD>;
    const parentState = parentHandler.doSelectStateFromGlobalState(globalState);
    return this.stateSelector(parentState);
  }

  public selectStateFromGlobalState(globalState: any): any {
    try {
      return this.doSelectStateFromGlobalState(globalState);
    } catch (e) {
      if (e === UNDEFINED_VALUE_CHAINING) {
        return undefined;
      }
      if (e === NULL_VALUE_CHAINING) {
        return null;
      }
      // Otherwise
      throw e;
    }
  }
}

/**
 * Returns the store components :
 * - the store hook
 * - the store dispatch
 * - the setter for the context
 * @param initialState the initial state.
 */
export function createDispatchableStore<SD extends AnyStoreDef>(
  initialState: SD['globalState'],
  dispatchErrorHandler?: DispatchErrorHandler<SD>
): [
  GlobalDispatch<SD>,
  GlobalStoreStateSelector<SD>,
  DispatchableContextSetter<SD['actionContext']>,
  StateChangeListenerRegisterer<SD['globalState']>,
] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let actionContext: SD['actionContext'] = {} as any;
  const useZustandStoreHook = create(
    devtools(
      (
        zustandSetState: (value: GlobalState<SD>) => void,
        zustandGetState: () => GlobalState<SD>
      ): GlobalState<SD> => {
        let lastFlushedState: SD['globalState'] = initialState;
        let state: SD['globalState'] = initialState;
        const getState: GetState<SD['globalState']> = (): SD['globalState'] => {
          return state;
        };
        const setState: SetState<SD['globalState']> = (newState: SD['globalState']): void => {
          state = newState;
        };
        const flushState = (): void => {
          if (state !== lastFlushedState) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { dispatch, state: oldState } = zustandGetState();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            logStateUpdate(oldState as any, state);
            const newZState = {
              // Keep dispatch & scope methods
              ...zustandGetState(),
              state,
            };
            zustandSetState(newZState);
            lastFlushedState = state;
          } else {
            log.info('State has not changed, state flush not performed');
          }
        };
        const rollbackState = (): void => {
          state = lastFlushedState;
        };
        const globalErrorHandler: DispatchErrorHandler<SD> = !dispatchErrorHandler
          ? NOOP_ERROR_HANDLER
          : dispatchErrorHandler;
        const [dispatch, actionDispatch] = createPublicDispatch(
          '$',
          undefined, // Root dispatch so, the argument is undefined
          undefined, // Root dispatch so, the argument is as well undefined
          setState,
          getState,
          getState,
          (): SD['actionContext'] => actionContext,
          createStateTransactionManagerWrapper(
            flushState,
            rollbackState,
            async (error: Error): Promise<void> => {
              await globalErrorHandler(actionDispatch, getState, error);
              flushState();
            }
          )
        );
        return {
          state: initialState,
          dispatch,
        };
      }
    )
  );

  // Create state selector
  const globalDispatch = useZustandStoreHook.getState().dispatch;
  const rootStateSelector = new Proxy(
    {} as any,
    new StateSelectorProxyHandler<SD>(useZustandStoreHook, globalDispatch)
  );

  const listeners: StateChangeListener<SD['globalState']>[] = [];
  useZustandStoreHook.subscribe((newState: GlobalState<SD> | null): void => {
    if (newState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dispatch, state: currentState } = newState;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const partial = currentState as any as SD['globalState'];
      listeners.forEach((l): void => l(partial));
    }
  });
  return [
    // Dispatch
    useZustandStoreHook.getState().dispatch,
    // Store selector
    rootStateSelector,
    // Context setter
    (ctx: Partial<SD['actionContext']>): void => {
      actionContext = { ...actionContext, ...ctx };
    },
    // State change listeners registerer
    (listener: StateChangeListener<SD['globalState']>): void => {
      listeners.push(listener);
      // Push the actual state
      listener(useZustandStoreHook.getState().state);
    },
  ];
}

export const installStateConsoleHook = <SD extends AnyStoreDef>(
  registerer: StateChangeListenerRegisterer<SD['globalState']>,
  hookName = 'state'
): void => {
  Reflect.set(window, hookName, (): SD['globalState'] | undefined => {
    let globalState: SD['globalState'] | undefined;
    registerer((newState: SD['globalState']): void => {
      globalState = newState;
    });
    Reflect.set(window, hookName, (): SD['globalState'] | undefined => {
      return globalState;
    });
    return globalState;
  });
};
