import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { FuelValues, GearboxValues } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { SelectOption } from '@stimcar/marketplace-libs-common';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { KanbanFiltersState, MPStoreDef } from '../../store/typings/store.js';
import {
  EMPTY_KANBAN_FILTERS_STATE,
  EMPTY_MULTI_SELECT_WITH_TEXT_STATE,
} from '../../store/store.js';
import { MultiSelect } from '../utils/filters/MultiSelect.js';
import { MultiSelectWithText } from '../utils/filters/MultiSelectWithText.js';
import { RangePicker } from '../utils/filters/RangePicker.js';
import { SearchBar } from '../utils/filters/SearchBar.js';

function clearFiltersAction({
  actionDispatch,
  getState,
}: ActionContext<MPStoreDef, KanbanFiltersState>) {
  const availableBrands = getState().brandFilter.availableValues;
  const clearedFilters: KanbanFiltersState = {
    ...EMPTY_KANBAN_FILTERS_STATE,
    brandFilter: {
      ...EMPTY_MULTI_SELECT_WITH_TEXT_STATE,
      availableValues: availableBrands,
    },
  };
  actionDispatch.setValue(clearedFilters);
}

interface KanbanFiltersProps {
  readonly $: StoreStateSelector<MPStoreDef, KanbanFiltersState>;
  readonly hasSearchBar: boolean;
  readonly onClearCallback: () => void;
  readonly onSearchCallback: () => void;
}

export function KanbanFilters({
  $,
  hasSearchBar,
  onClearCallback,
  onSearchCallback,
}: KanbanFiltersProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const currentYear = new Date().getFullYear();
  const selectedBrands = useGetState($.$brandFilter.$selectedValues);
  const selectedFuel = useGetState($.$fuelFilter.$selectedValues);
  const selectedGearbox = useGetState($.$gearboxFilter.$selectedValues);
  const brandFilterTitle = useMemo(
    () =>
      t('filters.type.brandFilter', {
        count: selectedBrands.length,
      }),
    [t, selectedBrands]
  );
  const fuelFilterTitle = useMemo(
    () =>
      t('filters.type.fuelFilter', {
        count: selectedFuel.length,
      }),
    [t, selectedFuel]
  );
  const gearboxFilterTitle = useMemo(
    () =>
      t('filters.type.gearboxFilter', {
        count: selectedGearbox.length,
      }),
    [t, selectedGearbox]
  );

  const gearboxValues: readonly SelectOption<GearboxValues>[] = useMemo(
    () => [
      {
        label: `${t('filters.values.gearbox.automaticGearbox')}`,
        value: 'auto',
      },
      {
        label: `${t('filters.values.gearbox.manualGearbox')}`,
        value: 'manual',
      },
    ],
    [t]
  );
  const fuelValues: readonly SelectOption<FuelValues>[] = useMemo(
    () => [
      {
        label: `${t('filters.values.fuel.dieselFuel')}`,
        value: 'diesel',
      },
      {
        label: `${t('filters.values.fuel.gasFuel')}`,
        value: 'gas',
      },
      {
        label: `${t('filters.values.fuel.hybridFuel')}`,
        value: 'hybrid',
      },
      {
        label: `${t('filters.values.fuel.electricFuel')}`,
        value: 'electric',
      },
    ],
    [t]
  );

  const clearFiltersActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(clearFiltersAction);
      onClearCallback();
    },
    [onClearCallback],
    $
  );

  return (
    <div className="fixed-grid has-1-cols m-2">
      <div className="grid is-row-gap-2">
        <h4 className="is-size-4">{t('filters.title')}</h4>
        {hasSearchBar && <SearchBar $={$} onSearchCallback={onSearchCallback} />}
        <details open>
          <summary className="is-size-6 mb-2 has-text-weight-bold">
            {t('filters.type.priceFilter')}
          </summary>
          <RangePicker
            $={$.$priceFilter}
            minValuePlaceholder="3 000"
            maxValuePlaceholder="10 000"
            minLimit={0}
            maxLimit={1_000_000}
          />
        </details>
        <details open>
          <summary className="is-size-6 mb-2 has-text-weight-bold">
            {t('filters.type.mileageFilter')}
          </summary>
          <RangePicker
            $={$.$mileageFilter}
            minValuePlaceholder="20 000"
            maxValuePlaceholder="100 000"
            minLimit={0}
            maxLimit={1_000_000}
          />
        </details>
        <details>
          <summary className="is-size-6 mb-2 has-text-weight-bold">{brandFilterTitle}</summary>
          <MultiSelectWithText
            $={$.$brandFilter}
            filterPlaceholder={t('filters.brandFilterPlaceholder')}
          />
        </details>
        <details>
          <summary className="is-size-6 mb-2 has-text-weight-bold">{fuelFilterTitle}</summary>
          <MultiSelect $={$.$fuelFilter} options={fuelValues} />
        </details>
        <details>
          <summary className="is-size-6 mb-2 has-text-weight-bold">{gearboxFilterTitle}</summary>
          <MultiSelect $={$.$gearboxFilter} options={gearboxValues} />
        </details>
        <details>
          <summary className="is-size-6 mb-2 has-text-weight-bold">
            {t('filters.type.yearFilter')}
          </summary>
          <RangePicker
            $={$.$yearFilter}
            minValuePlaceholder="2010"
            maxValuePlaceholder={`${currentYear}`}
            maxLimit={currentYear}
          />
        </details>
      </div>
      <div className="grid is-row-gap-0.5 mb-2">
        <button
          type="button"
          className="stimcar-gradient-button is-size-6"
          onClick={onSearchCallback}
        >
          {t('filters.searchButton')}
        </button>
        <button type="button" className="stimcar-white-button" onClick={clearFiltersActionCallback}>
          {t('filters.clearButton')}
        </button>
      </div>
    </div>
  );
}
