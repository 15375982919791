import React from 'react';
import { Route, Routes } from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState, useStateIsDefined, useWindowSizeTracker } from '@stimcar/libs-uikernel';
import { MarketplaceFrontendRoutes } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../store/typings/store.js';
import { BuyContent } from './buy/BuyContent.js';
import { Cars } from './cars/Cars.js';
import { KanbanDetailsLoader } from './cars/KanbanDetailsLoader.js';
import { ContactSuccessNotification } from './contact/ContactSuccessNotification.js';
import { HomeContent } from './home/HomeContent.js';
import { useOnNavigation } from './hooks/useOnNavigation.js';
import './i18n.js';
import { Footer } from './layout/Footer.js';
import { NavBar } from './layout/NavBar.js';
import { LegalMentions } from './legal/LegalMentions.js';
import { RefreshContent } from './refresh/RefreshContent.js';
import './scss/styles';
import { SellContent } from './sell/SellContent.js';
import { UnexpectedError } from './UnexpectedError.js';
import { ImageModalDialog } from './utils/ImageModalDialog.js';

export interface MarketplaceAppProps {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

export function MarketplaceApp({ $gs }: MarketplaceAppProps): JSX.Element {
  // Track the window size
  useWindowSizeTracker($gs);

  // React to navigation events
  useOnNavigation($gs);

  const hasError = useStateIsDefined($gs.$error);
  const bulmaMode = useGetState($gs.$window.$bulmaMode);
  return (
    <>
      <NavBar $gs={$gs} />
      <ContactSuccessNotification $gs={$gs} />
      {/* Add a left/right margin if the screen is under the desktop size */}
      <div
        className={`container is-max-widescreen mt-5 mb-2${bulmaMode === 'Mobile' || bulmaMode === 'Tablet' ? ' pl-3 pr-3' : ''}`}
      >
        {!hasError ? (
          <>
            <Routes>
              <Route
                path={MarketplaceFrontendRoutes.CAR_DETAILS(':companyId', ':siteId', ':kanbanId')}
                element={<KanbanDetailsLoader $gs={$gs} />}
              />
              <Route
                path={MarketplaceFrontendRoutes.CARS}
                element={<Cars $={$gs.$cars} $window={$gs.$window} />}
              />
              <Route path={MarketplaceFrontendRoutes.BUY} element={<BuyContent $gs={$gs} />} />
              <Route path={MarketplaceFrontendRoutes.SELL} element={<SellContent $gs={$gs} />} />
              <Route
                path={MarketplaceFrontendRoutes.REFRESH}
                element={<RefreshContent $gs={$gs} />}
              />
              <Route path={MarketplaceFrontendRoutes.LEGAL_MENTIONS} element={<LegalMentions />} />
              <Route path="*" element={<HomeContent $gs={$gs} />} />
            </Routes>
            <ImageModalDialog $={$gs.$imageModalDialog} />
          </>
        ) : (
          <UnexpectedError $gs={$gs} />
        )}
        <Footer $gs={$gs} />
      </div>
    </>
  );
}
