import React, { useCallback } from 'react';
import type { SelectOption } from '@stimcar/marketplace-libs-common';

export interface SelectProps<T extends string> {
  readonly ariaLabel: string;
  readonly selectedOption: T;
  readonly options: readonly SelectOption<T>[];
  readonly onChangeCallback: (value: T) => void;
}

export function Select<T extends string>({
  options,
  ariaLabel,
  selectedOption,
  onChangeCallback,
}: SelectProps<T>): JSX.Element {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeCallback(e.target.value as T);
    },
    [onChangeCallback]
  );
  return (
    <div className="control has-icons-left">
      <div className="select is-small">
        <select aria-label={ariaLabel} value={selectedOption} onChange={onChange}>
          {options.map((option) => (
            <option key={`select-sort-option-${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="icon is-small is-left">
        <i className="fa-solid fa-sort" />
      </div>
    </div>
  );
}
