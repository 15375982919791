import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { CarsFilters } from '../constants.js';

function addStringQueryParam(
  urlSearchParams: URLSearchParams,
  key: string,
  value: string | undefined
): void {
  if (isTruthyAndNotEmpty(value)) {
    urlSearchParams.append(key, value);
  }
}

function addStringArrayQueryParam(
  urlSearchParams: URLSearchParams,
  key: string,
  values: readonly string[] | undefined
): void {
  if (isTruthy(values)) {
    values.forEach((value) => {
      urlSearchParams.append(key, value);
    });
  }
}

function addNumberQueryParam(
  urlSearchParams: URLSearchParams,
  key: string,
  value: number | undefined
): void {
  if (isTruthy(value)) {
    const valueAsString = String(value);
    urlSearchParams.append(key, valueAsString);
  }
}

export function getQueryParams(
  offset?: number,
  size?: number,
  filters?: CarsFilters,
  sort?: string
): URLSearchParams {
  const urlSearchParams = new URLSearchParams();
  addNumberQueryParam(urlSearchParams, 'offset', offset);
  addNumberQueryParam(urlSearchParams, 'size', size);
  addStringQueryParam(urlSearchParams, 'textFilter', filters?.textFilter);
  addStringArrayQueryParam(urlSearchParams, 'brandFilter', filters?.brandFilter);
  addStringArrayQueryParam(urlSearchParams, 'fuelFilter', filters?.fuelFilter);
  addStringArrayQueryParam(urlSearchParams, 'gearboxFilter', filters?.gearboxFilter);
  addNumberQueryParam(urlSearchParams, 'minPriceFilter', filters?.minPriceFilter);
  addNumberQueryParam(urlSearchParams, 'maxPriceFilter', filters?.maxPriceFilter);
  addNumberQueryParam(urlSearchParams, 'minMileageFilter', filters?.minMileageFilter);
  addNumberQueryParam(urlSearchParams, 'maxMileageFilter', filters?.maxMileageFilter);
  addNumberQueryParam(urlSearchParams, 'minYearFilter', filters?.minYearFilter);
  addNumberQueryParam(urlSearchParams, 'maxYearFilter', filters?.maxYearFilter);
  addStringQueryParam(urlSearchParams, 'sort', sort);
  return urlSearchParams;
}
