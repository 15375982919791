import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { generateRange, isCurrentPage } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../../store/typings/store.js';

function generateSmallPaginationList(
  isDisabled: boolean,
  currentPage: number,
  totalPages: number,
  onPageChangeCallback: (newPage: number) => void
): JSX.Element {
  return (
    <ul className="pagination-list is-centered">
      {generateRange(1, totalPages).map((pageNumber) => {
        return (
          <PaginationButton
            index={pageNumber}
            isDisabled={isDisabled}
            key={`pagination-${pageNumber}`}
            isCurrent={isCurrentPage(currentPage, pageNumber)}
            onPageChangeCallback={onPageChangeCallback}
          />
        );
      })}
    </ul>
  );
}

function generatePaginationListWithEllipsis(
  isDisabled: boolean,
  currentPage: number,
  totalPages: number,
  onPageChangeCallback: (newPage: number) => void
): JSX.Element {
  return (
    <ul className="pagination-list is-centered">
      <PaginationButton
        index={1}
        isCurrent={false}
        key="pagination-1"
        isDisabled={isDisabled}
        onPageChangeCallback={onPageChangeCallback}
      />
      <li>
        <span key="pagination-ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      </li>
      {generateRange(currentPage - 2, currentPage + 2).map((pageNumber) => {
        return (
          <PaginationButton
            index={pageNumber}
            isDisabled={isDisabled}
            key={`pagination-${pageNumber}`}
            isCurrent={isCurrentPage(currentPage, pageNumber)}
            onPageChangeCallback={onPageChangeCallback}
          />
        );
      })}
      <li>
        <span key="pagination-ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      </li>
      <PaginationButton
        isCurrent={false}
        index={totalPages}
        key="pagination-last"
        isDisabled={isDisabled}
        onPageChangeCallback={onPageChangeCallback}
      />
    </ul>
  );
}

function generatePaginationStartWithEllipsis(
  isDisabled: boolean,
  currentPage: number,
  totalPages: number,
  onPageChangeCallback: (newPage: number) => void
): JSX.Element {
  return (
    <ul className="pagination-list is-centered">
      {generateRange(1, 8).map((pageNumber) => {
        return (
          <PaginationButton
            index={pageNumber}
            isDisabled={isDisabled}
            key={`pagination-${pageNumber}`}
            onPageChangeCallback={onPageChangeCallback}
            isCurrent={isCurrentPage(currentPage, pageNumber)}
          />
        );
      })}
      <li>
        <span key="pagination-ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      </li>
      <PaginationButton
        isCurrent={false}
        index={totalPages}
        key="pagination-last"
        isDisabled={isDisabled}
        onPageChangeCallback={onPageChangeCallback}
      />
    </ul>
  );
}

function generatePaginationEndWithEllipsis(
  isDisabled: boolean,
  currentPage: number,
  totalPages: number,
  onPageChangeCallback: (newPage: number) => void
): JSX.Element {
  return (
    <ul className="pagination-list is-centered">
      <PaginationButton
        index={1}
        key="pagination-1"
        isCurrent={false}
        isDisabled={isDisabled}
        onPageChangeCallback={onPageChangeCallback}
      />
      <li>
        <span key="pagination-ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      </li>
      {generateRange(totalPages - 5, totalPages).map((pageNumber) => {
        return (
          <PaginationButton
            index={pageNumber}
            isDisabled={isDisabled}
            key={`pagination-${pageNumber}`}
            isCurrent={isCurrentPage(currentPage, pageNumber)}
            onPageChangeCallback={onPageChangeCallback}
          />
        );
      })}
    </ul>
  );
}

function generatePaginationButton(
  isDisabled: boolean,
  currentPage: number,
  totalPages: number,
  onPageChangeCallback: (newPage: number) => void
): JSX.Element {
  if (totalPages > 8) {
    if (currentPage < 5) {
      return generatePaginationStartWithEllipsis(
        isDisabled,
        currentPage,
        totalPages,
        onPageChangeCallback
      );
    }
    if (totalPages - currentPage < 5) {
      return generatePaginationEndWithEllipsis(
        isDisabled,
        currentPage,
        totalPages,
        onPageChangeCallback
      );
    }
    return generatePaginationListWithEllipsis(
      isDisabled,
      currentPage,
      totalPages,
      onPageChangeCallback
    );
  }
  return generateSmallPaginationList(isDisabled, currentPage, totalPages, onPageChangeCallback);
}

interface PaginationButtonProps {
  readonly index: number;
  readonly isDisabled: boolean;
  readonly isCurrent: boolean;
  readonly onPageChangeCallback: (newPage: number) => void;
}

export function PaginationButton({
  index,
  isCurrent,
  isDisabled,
  onPageChangeCallback,
}: PaginationButtonProps): JSX.Element {
  const changePageActionCallback = useCallback(() => {
    onPageChangeCallback(index);
  }, [onPageChangeCallback, index]);

  const isCurrentLabel = isCurrent ? 'stimcar-gradient-button' : '';
  return (
    <li>
      <button
        type="button"
        disabled={isDisabled}
        onClick={changePageActionCallback}
        className={`pagination-link ${isCurrentLabel}`}
      >
        {index}
      </button>
    </li>
  );
}

interface PaginationProps {
  readonly isDisabled?: boolean;
  readonly currentPage: number;
  readonly $totalPages: StoreStateSelector<MPStoreDef, number>;
  readonly onPageChangeCallback: (newPage: number) => void;
}

export function Pagination({
  currentPage,
  $totalPages,
  isDisabled = false,
  onPageChangeCallback,
}: PaginationProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const totalPages = useGetState($totalPages);

  const previousPageActionCallback = useCallback(() => {
    onPageChangeCallback(currentPage - 1);
  }, [onPageChangeCallback, currentPage]);

  const nextPageActionCallback = useCallback(() => {
    onPageChangeCallback(currentPage + 1);
  }, [onPageChangeCallback, currentPage]);

  const isPreviousBtnDisabled = useMemo(
    () => isDisabled || currentPage === 1,
    [isDisabled, currentPage]
  );
  const isNextBtnDisabled = useMemo(
    () => isDisabled || currentPage === totalPages,
    [isDisabled, currentPage, totalPages]
  );

  const paginationButtons = generatePaginationButton(
    isDisabled,
    currentPage,
    totalPages,
    onPageChangeCallback
  );

  return (
    <nav className="pagination is-centered is-mobile is-rounded is-small my-2" role="navigation">
      <button
        type="button"
        className="pagination-previous"
        onClick={previousPageActionCallback}
        disabled={isPreviousBtnDisabled}
      >
        {t('pagination.previousPage')}
      </button>
      <button
        type="button"
        className="pagination-next"
        onClick={nextPageActionCallback}
        disabled={isNextBtnDisabled}
      >
        {t('pagination.nextPage')}
      </button>
      {paginationButtons}
    </nav>
  );
}
