import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenWidthLessThan } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { HorizontalCard } from '../HorizontalCard.js';
import './ServicesSection.scss';

const SERVICES: readonly string[] = ['expertise', 'quality', 'guarantee', 'aftersales'];

interface ServicesSectionProps extends MarketplaceAppProps {
  readonly mode: 'buy' | 'sell';
}

export function ServicesSection({ $gs, mode }: ServicesSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isSmallDevice = useScreenWidthLessThan($gs.$window, 1215);
  return (
    <section className="services-section fixed-grid has-1-cols has-2-cols-desktop pl-3">
      <div className={`grid ${isSmallDevice ? 'small-device-grid' : ''}`}>
        {SERVICES.map((service) => (
          <div className="cell" key={service}>
            <HorizontalCard
              $gs={$gs}
              image={`/img/${mode}/${service}.webp`}
              alt={t(`common.services.${service}.alt`)}
              title={t(`common.services.${service}.title`)}
              subtitle={t(`common.services.${service}.subtitle`)}
              content={<p>{t(`common.services.${service}.content`)}</p>}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
