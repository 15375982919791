import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { MarketplaceKanbanSearchResult } from '@stimcar/marketplace-libs-common';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { MarketplaceBackendRoutes } from '@stimcar/marketplace-libs-common';
import type { KanbanCarouselState, MPStoreDef } from '../../../store/typings/store.js';
import { NavigationButton } from '../NavigationButton.js';
import { WithImageBackground } from '../WithImageBackground.js';
import { KanbanCard } from './KanbanCard.js';
import './KanbanCarousel.scss';

async function searchAction(
  { httpClient, actionDispatch, getState }: ActionContext<MPStoreDef, KanbanCarouselState>,
  isMobile: boolean
): Promise<void> {
  const { currentPage } = getState();
  const pageSize = isMobile ? 1 : 3;

  try {
    const response = await httpClient.httpGetAsJson<MarketplaceKanbanSearchResult>(
      MarketplaceBackendRoutes.LIST_KANBAN_SUMMARIES(currentPage - 1, pageSize)
    );
    const kanbanCarousel: KanbanCarouselState = {
      kanbanList: {
        kanbans: response.kanbans,
        totalResults: response.totalResults,
      },
      currentPage: response.currentPage,
      totalPages: response.totalPages,
    };

    actionDispatch.setValue(kanbanCarousel);
  } catch {}
}

interface CarouselProps {
  readonly $: StoreStateSelector<MPStoreDef, KanbanCarouselState>;
  readonly bulmaTheme: string;
  readonly isMobile: boolean;
}

export function KanbanCarousel({ $, isMobile, bulmaTheme }: CarouselProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const kanbans = useGetState($.$kanbanList.$kanbans);
  const currentPage = useGetState($.$currentPage);
  const totalPages = useGetState($.$totalPages);

  const isPreviousButtonDisabled = useMemo(() => {
    return currentPage === 1;
  }, [currentPage]);
  const isNextButtonDisabled = useMemo(() => {
    return currentPage === totalPages;
  }, [currentPage, totalPages]);

  const searchActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(searchAction, isMobile);
    },
    [isMobile],
    $
  );
  const previousPageActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      actionDispatch.setProperty('currentPage', currentPage - 1);
      await actionDispatch.execCallback(searchActionCallback);
    },
    [searchActionCallback, currentPage],
    $
  );

  const nextPageActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      actionDispatch.setProperty('currentPage', currentPage + 1);
      await actionDispatch.execCallback(searchActionCallback);
    },
    [searchActionCallback, currentPage],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchActionCallback();
  }, [searchActionCallback]);

  return (
    <section className="carousel-section hero">
      <WithImageBackground
        height="130px"
        alt={t('common.patternAlt')}
        width={isMobile ? '350px' : '1152px'}
        childrenClassName="is-absolute-centered"
        src={`/img/background/pattern-${bulmaTheme}.svg`}
      >
        <div className={`title is-5 m-4 ${isMobile && 'mt-5'}`}>{t('kanbanCarousel.findCar')}</div>
        <div className="m-4">
          <NavigationButton label={t(`navBar.cars`)} to="cars" />
        </div>
      </WithImageBackground>
      {kanbans.length > 0 && (
        <div className={`columns is-align-items-center ${isMobile ? 'is-mobile m-2' : 'm-4'}`}>
          <div className={`column has-text-right ${isMobile ? 'mr-2' : 'mr-4'}`}>
            <button
              type="button"
              disabled={isPreviousButtonDisabled}
              onClick={previousPageActionCallback}
              aria-label={t('kanbanCarousel.previousAriaLabel')}
              className="button has-no-border p-0 has-text-inherit"
            >
              <span className="icon">
                <i className="fa fa-circle-arrow-left fa-2x" />
              </span>
            </button>
          </div>
          {kanbans.map((kanban) => (
            <div
              key={kanban.id}
              className={`column ${isMobile ? 'is-two-thirds' : 'is-one-quarter'}`}
            >
              <KanbanCard
                id={kanban.id}
                key={kanban.id}
                $kanbans={$.$kanbanList.$kanbans}
                imageHeight={isMobile ? '150px' : '192px'}
                imageWidth={isMobile ? '200px' : '256px'}
              />
            </div>
          ))}
          <div className={`column has-text-left ${isMobile ? 'ml-2' : 'ml-4'}`}>
            <button
              type="button"
              disabled={isNextButtonDisabled}
              onClick={nextPageActionCallback}
              aria-label={t('kanbanCarousel.nextAriaLabel')}
              className="button has-no-border p-0 has-text-inherit"
            >
              <span className="icon">
                <i className="fa fa-circle-arrow-right fa-2x" />
              </span>
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
