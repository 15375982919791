import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  NoArgActionCallback,
} from '@stimcar/libs-uikernel';
import {
  LightDarkThemeSelector,
  useActionCallback,
  useGetState,
  useSetCallback,
} from '@stimcar/libs-uikernel';
import type { MPStoreDef, StoreState } from '../../store/typings/store.js';
import { ContactButton } from '../contact/ContactButton.js';
import { Image } from '../utils/image/Image.js';
import './NavBar.scss';

function gotoHomeAction({ navigate, actionDispatch }: ActionContext<MPStoreDef, StoreState>) {
  // Reset errors
  actionDispatch.setProperty('error', undefined);
  // Goto home
  navigate('/');
}

interface NavBarLinkProps {
  readonly id: string;
  readonly onClick: NoArgActionCallback<MPStoreDef>;
}

function NavBarLink({ id, onClick }: NavBarLinkProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <NavLink
      to={`/${id}`}
      onClick={onClick}
      className={({ isActive }) =>
        `navbar-item ${isActive ? 'is-active has-text-weight-bold has-text-dark has-light-background' : ''}`
      }
    >
      {t(`navBar.${id}`).toLocaleUpperCase()}
    </NavLink>
  );
}

interface NavBarProps {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

export function NavBar({ $gs }: NavBarProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isBurgerMenuActive = useGetState($gs.$navbar.$isBurgerMenuActive);

  const gotoHomeActionCallback = useActionCallback(gotoHomeAction, [], $gs);
  const toggleBurgerMenuCallback = useSetCallback(
    $gs.$navbar.$isBurgerMenuActive,
    !isBurgerMenuActive
  );
  const closeBurgerMenuCallback = useSetCallback($gs.$navbar.$isBurgerMenuActive, false);

  return (
    <nav
      role="navigation"
      className="navbar marketplace-navbar is-fixed-top py-1"
      aria-label={t('navBar.navBarAriaLabel')}
    >
      <div className="container is-max-widescreen">
        <div className="navbar-brand">
          <button
            type="button"
            className="navbar-item"
            onClick={gotoHomeActionCallback}
            aria-label={t('navBar.goToHomeAriaLabel')}
          >
            <Image
              height="50px"
              width="180px"
              loading="eager"
              fetchPriority="high"
              className="stimcar-logo"
              alt={t('common.stimcarLogoAlt')}
              src={`stimcarlogo-${bulmaTheme}.svg`}
            />
          </button>
          <button
            type="button"
            aria-expanded="false"
            className="navbar-burger"
            data-target="navbarItems"
            onClick={toggleBurgerMenuCallback}
            aria-label={t('navBar.menuAriaLabel')}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>
        <div id="navbarItems" className={`navbar-menu ${isBurgerMenuActive ? ' is-active' : ''}`}>
          <div className="navbar-end">
            <NavBarLink id="buy" onClick={closeBurgerMenuCallback} />
            <NavBarLink id="sell" onClick={closeBurgerMenuCallback} />
            <NavBarLink id="refresh" onClick={closeBurgerMenuCallback} />
            <NavBarLink id="cars" onClick={closeBurgerMenuCallback} />
            <div className="navbar-item">
              <ContactButton
                $gs={$gs}
                reason="request"
                label={t('navBar.contactUs')}
                className="stimcar-grey-button"
              />
            </div>
            <div className="navbar-item">
              <LightDarkThemeSelector $gs={$gs} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
