import React from 'react';
import { useTranslation } from 'react-i18next';
import type { MarketplaceAppProps } from '../App.js';
import { ExperienceSection } from '../utils/experience/ExperienceSection.js';
import { HeadSection } from '../utils/head-section/HeadSection.js';
import { ServicesSection } from '../utils/services/ServicesSection.js';
import { StimerSection } from '../utils/stimers/StimersSection.js';
import { WhyStimcarSection } from '../utils/why-stimcar/WhyStimcarSection.js';
import './SellContent.scss';

type Props = MarketplaceAppProps;

const MODE = 'sell';

export function SellContent({ $gs }: Props): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <>
      <HeadSection $gs={$gs} mode={MODE} className="sell-head-section" />
      <StimerSection $gs={$gs} mode={MODE} />
      <ExperienceSection $gs={$gs} mode={MODE} alt={t('sell.stimcarExperienceAlt')} />
      <WhyStimcarSection $gs={$gs} src="/img/sell/shining-keys.webp" mode={MODE} />
      <section className="my-5 py-5">
        <h4 className="title is-4 has-text-centered">{t('sell.sellWithStimcar')}</h4>
      </section>
      <ServicesSection $gs={$gs} mode={MODE} />
    </>
  );
}
