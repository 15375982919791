import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

export function formatPrice(number: number, digits?: number): string {
  const minimumFractionDigits = digits ?? (Number.isInteger(number) ? 0 : 2);
  return new Intl.NumberFormat('fr-FR', {
    currency: 'EUR',
    style: 'currency',
    useGrouping: true,
    minimumFractionDigits,
    maximumFractionDigits: 2,
  }).format(number);
}

export function formatKilometers(kilometers: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'unit',
    unit: 'kilometer',
    unitDisplay: 'short',
  }).format(kilometers);
}

export function formatCompanyId(companyId: string): string {
  if (isTruthyAndNotEmpty(companyId)) {
    if (companyId === 'crnm1') {
      return 'Nantes';
    }
    return companyId.charAt(0).toUpperCase() + companyId.slice(1);
  }
  return '';
}
