import React from 'react';
import { useTranslation } from 'react-i18next';
import type { MarketplaceAppProps } from '../../App.js';
import type { CardContent } from '../contact-and-cards/ContactAndCards.js';
import { ContactAndCards } from '../contact-and-cards/ContactAndCards.js';

const cards: readonly CardContent[] = [
  {
    title: 'Kylian B.',
    subtitle: 'Nantes',
    alt: 'Kylian B. Stimer',
    picture: '/img/stimers/stimer-1.webp',
  },
  {
    subtitle: 'Nantes',
    title: 'Goulven E.',
    alt: 'Goulven E. Stimer',
    picture: '/img/stimers/stimer-2.webp',
  },
  {
    title: 'Mehdi O.',
    subtitle: 'Nantes',
    alt: 'Mehdi O. Stimer',
    picture: '/img/stimers/stimer-3.webp',
  },
];

interface StimerSectionProps extends MarketplaceAppProps {
  readonly mode: 'buy' | 'sell';
}

export function StimerSection({ $gs, mode }: StimerSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <ContactAndCards
      $gs={$gs}
      cards={cards}
      contactReason={mode}
      cardTitle={t('common.stimer.stimerPeopleTitle')}
      contactSubtitle={t(`${mode}.stimer.stimerDefinition`)}
      contactTitle={t('common.stimer.stimerDefinitionTitle')}
      cardSubtitle={t('common.stimer.stimerPeopleDefinition')}
      contactButtonLabel={t('common.stimer.stimerDefinitionButton')}
    />
  );
}
