import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  CarsFilters,
  CarsSort,
  MarketplaceAvailableBrandsResult,
  MarketplaceKanbanSearchResult,
} from '@stimcar/marketplace-libs-common';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  DEFAULT_CARS_ITEMS_PER_PAGE,
  MarketplaceBackendRoutes,
} from '@stimcar/marketplace-libs-common';
import type { CarsState, KanbanFiltersState, MPStoreDef } from '../../store/typings/store.js';
import { EMPTY_KANBAN_FILTERS_STATE } from '../../store/store.js';
import {
  clearSearchParamsFromFilters,
  convertFiltersToUrlSearchParams,
  getCurrentPageFromSearchParam,
  getFullSearchParams,
  getSortPageFromSearchParam,
  getUpdatedFiltersFromSearchParams,
  PAGE_SEARCH_PARAM_KEY,
  SORT_SEARCH_PARAM_KEY,
} from '../utils/url/urlUtils.js';

function getUpdatedFiltersFromPreviousUserFilterInputs(
  previousFilters: KanbanFiltersState,
  newFilters: KanbanFiltersState
): KanbanFiltersState {
  return {
    textFilter:
      newFilters.textFilter === EMPTY_KANBAN_FILTERS_STATE.textFilter
        ? previousFilters.textFilter
        : newFilters.textFilter,
    brandFilter: {
      ...newFilters.brandFilter,
      selectedValues:
        newFilters.brandFilter.selectedValues.length === 0
          ? previousFilters.brandFilter.selectedValues
          : newFilters.brandFilter.selectedValues,
    },
    fuelFilter: {
      ...newFilters.fuelFilter,
      selectedValues:
        newFilters.fuelFilter.selectedValues.length === 0
          ? previousFilters.fuelFilter.selectedValues
          : newFilters.fuelFilter.selectedValues,
    },
    gearboxFilter: {
      ...newFilters.gearboxFilter,
      selectedValues:
        newFilters.gearboxFilter.selectedValues.length === 0
          ? previousFilters.gearboxFilter.selectedValues
          : newFilters.gearboxFilter.selectedValues,
    },
    yearFilter: {
      minValue:
        newFilters.yearFilter.minValue === EMPTY_KANBAN_FILTERS_STATE.yearFilter.minValue
          ? previousFilters.yearFilter.minValue
          : newFilters.yearFilter.minValue,
      maxValue:
        newFilters.yearFilter.maxValue === EMPTY_KANBAN_FILTERS_STATE.yearFilter.maxValue
          ? previousFilters.yearFilter.maxValue
          : newFilters.yearFilter.maxValue,
    },
    mileageFilter: {
      minValue:
        newFilters.mileageFilter.minValue === EMPTY_KANBAN_FILTERS_STATE.mileageFilter.minValue
          ? previousFilters.mileageFilter.minValue
          : newFilters.mileageFilter.minValue,
      maxValue:
        newFilters.mileageFilter.maxValue === EMPTY_KANBAN_FILTERS_STATE.mileageFilter.maxValue
          ? previousFilters.mileageFilter.maxValue
          : newFilters.mileageFilter.maxValue,
    },
    priceFilter: {
      minValue:
        newFilters.priceFilter.minValue === EMPTY_KANBAN_FILTERS_STATE.priceFilter.minValue
          ? previousFilters.priceFilter.minValue
          : newFilters.priceFilter.minValue,
      maxValue:
        newFilters.priceFilter.maxValue === EMPTY_KANBAN_FILTERS_STATE.priceFilter.maxValue
          ? previousFilters.priceFilter.maxValue
          : newFilters.priceFilter.maxValue,
    },
  };
}

async function loadAvailableBrandsAction({
  httpClient,
  actionDispatch,
}: ActionContext<MPStoreDef, readonly string[]>): Promise<void> {
  try {
    const response = await httpClient.httpGetAsJson<MarketplaceAvailableBrandsResult>(
      MarketplaceBackendRoutes.LIST_KANBAN_BRANDS
    );
    actionDispatch.setValue(response.availableBrands);
  } catch {}
}

async function searchAction(
  { httpClient, actionDispatch, getState }: ActionContext<MPStoreDef, CarsState>,
  currentPage: number,
  sort: CarsSort
): Promise<void> {
  const { filters } = getState();

  const searchFilters: CarsFilters = {
    textFilter: filters.textFilter,
    brandFilter: filters.brandFilter.selectedValues,
    gearboxFilter: filters.gearboxFilter.selectedValues,
    fuelFilter: filters.fuelFilter.selectedValues,
    minPriceFilter: filters.priceFilter.minValue,
    maxPriceFilter: filters.priceFilter.maxValue,
    minMileageFilter: filters.mileageFilter.minValue,
    maxMileageFilter: filters.mileageFilter.maxValue,
    minYearFilter: filters.yearFilter.minValue,
    maxYearFilter: filters.yearFilter.maxValue,
  };

  try {
    const { kanbans, totalPages, totalResults } =
      await httpClient.httpGetAsJson<MarketplaceKanbanSearchResult>(
        MarketplaceBackendRoutes.LIST_KANBAN_SUMMARIES(
          currentPage - 1,
          DEFAULT_CARS_ITEMS_PER_PAGE,
          searchFilters,
          sort
        )
      );

    actionDispatch.setProperty('kanbanList', {
      kanbans,
      totalResults,
    });
    actionDispatch.setProperty('totalPages', totalPages);
    actionDispatch.setProperty('isMobileFilterOpened', false);
  } finally {
    actionDispatch.scopeProperty('kanbanList').setProperty('isLoading', false);
  }
}

export function useCarsSearch($: StoreStateSelector<MPStoreDef, CarsState>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = useMemo(
    () => getCurrentPageFromSearchParam(searchParams.get(PAGE_SEARCH_PARAM_KEY)),
    [searchParams]
  );
  const onPageChangeCallback = useCallback(
    (newPage: number): void => {
      setSearchParams((previousSearchParams) => ({
        ...Object.fromEntries(previousSearchParams),
        [PAGE_SEARCH_PARAM_KEY]: newPage.toString(),
      }));
    },
    [setSearchParams]
  );

  const onSearchParamsChangeActionCallback = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { filters } = getState();

      const currentPage = getCurrentPageFromSearchParam(searchParams.get(PAGE_SEARCH_PARAM_KEY));
      const sort = getSortPageFromSearchParam(searchParams.get(SORT_SEARCH_PARAM_KEY));
      const updatedFiltersFromSearchParams = getUpdatedFiltersFromSearchParams(
        filters,
        searchParams
      );

      actionDispatch.setProperty('filters', updatedFiltersFromSearchParams);

      await actionDispatch.exec(searchAction, currentPage, sort);

      const { totalPages } = getState();

      if (currentPage > totalPages) {
        onPageChangeCallback(1);
      }

      const updatedFiltersFromPreviousUserFilterInputs =
        getUpdatedFiltersFromPreviousUserFilterInputs(filters, updatedFiltersFromSearchParams);
      actionDispatch.setProperty('filters', updatedFiltersFromPreviousUserFilterInputs);
    },
    [searchParams, onPageChangeCallback],
    $
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onSearchParamsChangeActionCallback();
  }, [onSearchParamsChangeActionCallback]);

  const loadBrandsActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(loadAvailableBrandsAction);
    },
    [],
    $.$filters.$brandFilter.$availableValues
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadBrandsActionCallback();
  }, [loadBrandsActionCallback]);

  const filters = useGetState($.$filters);
  const onSearchCallback = useCallback((): void => {
    const filtersSearchParams = convertFiltersToUrlSearchParams(filters);
    setSearchParams((previousSearchParams) =>
      getFullSearchParams(previousSearchParams, filtersSearchParams)
    );
  }, [filters, setSearchParams]);

  const onClearCallback = useCallback((): void => {
    setSearchParams((previousSearchParams) => clearSearchParamsFromFilters(previousSearchParams));
  }, [setSearchParams]);

  const sort: CarsSort = useMemo(
    () => getSortPageFromSearchParam(searchParams.get(SORT_SEARCH_PARAM_KEY)),
    [searchParams]
  );
  const onSortChangeCallback = useCallback(
    (newSort: CarsSort): void => {
      setSearchParams((previousSearchParams) => ({
        ...Object.fromEntries(previousSearchParams),
        [SORT_SEARCH_PARAM_KEY]: newSort,
      }));
    },
    [setSearchParams]
  );

  return {
    sort,
    currentPage,
    onClearCallback,
    onSearchCallback,
    onPageChangeCallback,
    onSortChangeCallback,
  };
}
