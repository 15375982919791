import type { DependencyList } from 'react';
import { useCallback, useMemo } from 'react';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import type {
  Action,
  ActionCallback,
  AnyStoreDef,
  ArrayItemStateType,
  Dispatch,
  NoArgActionCallback,
  State,
  StoreStateSelector,
} from './typings/index.js';
import {
  extractInternalSelectorInfos,
  newArrayItemSelector,
  newRecordItemSelector,
  newSelectorWithChangeTrigger,
} from './zustand-dispatch.js';

export const useGetState = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>
): S => {
  const infos = extractInternalSelectorInfos($);
  const { useStoreHook } = infos;
  return useStoreHook((gState) => infos.selectStateFromGlobalState(gState.state));
};

export const useStateIsDefined = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>
): $ is StoreStateSelector<SD, NonNullable<S>> => {
  return useStateMatches($, isTruthy);
};

export const useStateMatches = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>,
  predicateOrValue: ((value: S) => boolean) | S
): $ is StoreStateSelector<SD, NonNullable<S>> => {
  const infos = extractInternalSelectorInfos($);
  const { useStoreHook } = infos;
  const predicate =
    typeof predicateOrValue === 'function'
      ? predicateOrValue
      : (value: S) => value === predicateOrValue;
  return useStoreHook((gState) => predicate(infos.selectStateFromGlobalState(gState.state)));
};

export const useActionCallback = <SD extends AnyStoreDef, S extends State, A extends Action<SD, S>>(
  action: A,
  deps: DependencyList,
  $: StoreStateSelector<SD, S>
): ActionCallback<SD, A> => {
  const dispatch = useMemo(() => extractInternalSelectorInfos($).dispatch, [$]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(dispatch.createActionCallback(action), [dispatch, ...deps]);
};

export type ActionToActionCallbackConverter<SD extends AnyStoreDef, S extends State> = <
  A extends Action<SD, S>,
>(
  action: A
) => ActionCallback<SD, A>;

/**
 * This hook is used to create any object structure (component props, ...)
 * that contains action callbacks.
 *
 * In such situation, it is not possible to use the useActionCallback
 * hook as the object may contain more than one action callback, with
 * conditions or iterations (which is hard to handle using only hooks).
 */
export const useObjectWithNestedActionCallbacks = <SD extends AnyStoreDef, S extends State, T>(
  build: (toActionCallback: ActionToActionCallbackConverter<SD, S>) => T,
  deps: DependencyList,
  $: StoreStateSelector<SD, S>
): T => {
  const dispatch = useMemo(() => extractInternalSelectorInfos($).dispatch, [$]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo((): T => build(dispatch.createActionCallback), [dispatch, ...deps]);
};

export const useSetCallback = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>,
  value: S
): NoArgActionCallback<SD> => {
  const selectorInfos = extractInternalSelectorInfos($);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<SD, any> = useMemo(
    () =>
      extractInternalSelectorInfos(nonnull(selectorInfos.parent)).dispatch as Dispatch<SD, State>,
    [selectorInfos.parent]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    dispatch.createActionCallback(({ actionDispatch }): void => {
      actionDispatch.setProperty($.key, value);
    }),
    [dispatch, $.key, value]
  );
};

export const useGetArrayItemIds = <SD extends AnyStoreDef, S extends ArrayItemStateType>(
  $: StoreStateSelector<SD, readonly S[]>
): readonly string[] => {
  const infos = extractInternalSelectorInfos($);
  const { useStoreHook } = infos;
  return useStoreHook((gState) =>
    infos.selectStateFromGlobalState(gState.state).map(({ id }) => id)
  );
};

export const useGetArrayLength = <SD extends AnyStoreDef, S extends ArrayItemStateType>(
  $: StoreStateSelector<SD, readonly S[]>
): number => {
  const infos = extractInternalSelectorInfos($);
  const { useStoreHook } = infos;
  return useStoreHook((gState) => infos.selectStateFromGlobalState(gState.state).length);
};

export const useArrayItemSelector = <SD extends AnyStoreDef, S extends ArrayItemStateType>(
  $: StoreStateSelector<SD, readonly S[]>,
  id: string
): StoreStateSelector<SD, S> => {
  return useMemo(() => newArrayItemSelector($, id), [$, id]);
};

export const useGetRecordItemKeys = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, Record<string, S>>
): readonly string[] => {
  const infos = extractInternalSelectorInfos($);
  const { useStoreHook } = infos;
  return useStoreHook((gState) => keysOf(infos.selectStateFromGlobalState(gState.state)));
};

export const useRecordItemSelector = <
  SD extends AnyStoreDef,
  S extends State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  K extends keyof any = string,
>(
  $: StoreStateSelector<SD, Record<K, S>>,
  key: K
): StoreStateSelector<SD, S> => {
  return useMemo(() => newRecordItemSelector($, key), [$, key]);
};

export const useSelectorWithChangeTrigger = <SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>,
  onChangeActionCallback: NoArgActionCallback<SD>
): StoreStateSelector<SD, S> => {
  return useMemo(
    () => newSelectorWithChangeTrigger($, onChangeActionCallback),
    [$, onChangeActionCallback]
  );
};

/**
 *
 * @param $ Automatically retrieves the warning associated to the current selector.
 * The warning has the same key as the current field, but resides in the "warnings" object.
 */
export function useFormFieldWarning<SD extends AnyStoreDef, S extends State>(
  $: StoreStateSelector<SD, S>
): string | undefined {
  const { parent, pathKeys, useStoreHook } = extractInternalSelectorInfos($);
  return useStoreHook((gState) => {
    if (!parent) {
      return undefined;
    }
    const parentInfos = extractInternalSelectorInfos(parent);
    const parentState = parentInfos.selectStateFromGlobalState(gState.state);
    if (!parentState) {
      return undefined;
    }
    const { warnings } = parentState;
    if (warnings === undefined) {
      return undefined;
    }
    const warning = warnings[pathKeys[pathKeys.length - 1]];
    return warning;
  });
}
