import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { Image } from '../image/Image.js';
import { WithImageBackground } from '../WithImageBackground.js';
import './ExperienceSection.scss';

interface ExperienceSectionProps extends MarketplaceAppProps {
  readonly alt: string;
  readonly hasNoBottomImage?: boolean;
  readonly mode: 'buy' | 'sell' | 'refresh';
}

export function ExperienceSection({
  $gs,
  alt,
  mode,
  hasNoBottomImage = false,
}: ExperienceSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  const imageSource = isMobile
    ? `img/${mode}/experience-mobile.webp`
    : `img/${mode}/experience.webp`;

  return (
    <section className="experience-section hero">
      <WithImageBackground
        alt={t('common.patternAlt')}
        className={isMobile ? 'mobile' : ''}
        height={isMobile ? '185px' : '110px'}
        width={isMobile ? '350px' : '1152px'}
        childrenClassName="is-absolute-centered"
        src={`/img/background/pattern-${bulmaTheme}.svg`}
      >
        <div className="has-text-centered m-5">
          <h5 className="title is-4 mb-2">{t(`${mode}.experience.title`)}</h5>
          <p className="subtitle has-text-primary">{t(`${mode}.experience.subtitle`)}</p>
        </div>
      </WithImageBackground>
      {!hasNoBottomImage && (
        <div className="card-image">
          <figure className="image">
            <Image
              alt={alt}
              src={`${imageSource}`}
              width={isMobile ? '350px' : '1152px'}
              height={isMobile ? '260px' : '420px'}
            />
          </figure>
        </div>
      )}
    </section>
  );
}
