import { WAN_API_ROUTE_PREFIX } from '@stimcar/libs-kernel';
import { getQueryParams } from './utils/queryParamUtils.js';

export const DEFAULT_CARS_ITEMS_PER_PAGE = 9;
export const KIBANA_MARKETPLACE_STATUS_KEYWORD = 'marketplaceStatus.keyword';
export const KIBANA_INFOS_BRAND_KEYWORD = 'infos.brand.keyword';
export const KIBANA_INFOS_MILEAGE = 'infos.mileage';
export const KIBANA_INFOS_DATE_OF_REGISTRATION = 'infos.dateOfRegistration';
export const KIBANA_ATTRIBUTES_FUEL_KEYWORD = 'attributes.fuel.keyword';
export const KIBANA_ATTRIBUTES_FUEL = 'attributes.fuel';
export const KIBANA_ATTRIBUTES_GEARBOX_KEYWORD = 'attributes.gearbox.keyword';
export const KIBANA_ATTRIBUTES_GEARBOX = 'attributes.gearbox';
export const KIBANA_ATTRIBUTES_PUBLIC_PRICE = 'attributes.marketplacePublicSellingPrice';

export type CarsFilters = {
  textFilter?: string;
  brandFilter?: readonly string[];
  gearboxFilter?: readonly string[];
  fuelFilter?: readonly string[];
  minPriceFilter?: number;
  maxPriceFilter?: number;
  minMileageFilter?: number;
  maxMileageFilter?: number;
  minYearFilter?: number;
  maxYearFilter?: number;
};

export const CARS_SORTS = ['', 'price-asc', 'price-desc', 'mileage-asc', 'mileage-desc'] as const;
export type CarsSort = (typeof CARS_SORTS)[number];

export type SelectOption<T extends string> = {
  readonly value: T;
  readonly label: string;
};

export const MarketplaceFrontendRoutes = {
  HOME: '/',
  BUY: '/acheter',
  SELL: '/vendre',
  REFRESH: '/reconditionner',
  LEGAL_MENTIONS: '/mentions-legales',
  CARS: '/nos-vehicules',
  SITES: '/nos-centres',
  CAR_DETAILS: (companyId: string, siteId: string, kanbanId: string): string =>
    `/vehicule/${companyId}/${siteId}/${kanbanId}`,
};

export const MarketplaceBackendRoutes = {
  LIST_KANBAN_BRANDS: `${WAN_API_ROUTE_PREFIX}/kanbans/brands`,
  LIST_KANBAN_SUMMARIES: (
    offset?: number,
    size?: number,
    filters?: CarsFilters,
    sort?: CarsSort
  ): string => {
    const baseUrl = `${WAN_API_ROUTE_PREFIX}/kanbans/list`;
    const urlSearchParams = getQueryParams(offset, size, filters, sort);
    return urlSearchParams.size > 0 ? `${baseUrl}?${urlSearchParams.toString()}` : `${baseUrl}`;
  },
  KANBAN_SUMMARY: (companyId: string, siteId: string, kanbanId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/${companyId}/${siteId}/${kanbanId}`,
  LIST_ATTACHMENTS: (companyId: string, siteId: string, kanbanId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/list/${companyId}/${siteId}/${kanbanId}`,
  ATTACHMENT: (companyId: string, siteId: string, kanbanId: string, filename: string): string => {
    return `${WAN_API_ROUTE_PREFIX}/attachment/${companyId}/${siteId}/${kanbanId}/raw/${encodeURI(filename)}`;
  },
  KANBAN_OPENGRAPH_PICTURE: (
    companyId: string,
    siteId: string,
    kanbanId: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/${companyId}/${siteId}/${kanbanId}/opengraph/${encodeURI(
      filename
    )}`,
  SEND_CONTACT_MAIL: `${WAN_API_ROUTE_PREFIX}/contact`,
};
