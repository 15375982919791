import React from 'react';
import { Image } from './image/Image.js';

interface VerticalCardProps {
  readonly alt: string;
  readonly title: string;
  readonly picture: string;
  readonly subtitle?: string;
  readonly isMobile?: boolean;
  readonly titleClassName: string;
  readonly subtitleClassName: string;
}

export function VerticalCard({
  picture,
  alt,
  title,
  subtitle,
  titleClassName,
  isMobile = false,
  subtitleClassName,
}: VerticalCardProps): JSX.Element {
  return (
    <div className="card has-square-border">
      <div className="card-image">
        <figure className="image is-4by3">
          <Image
            alt={alt}
            src={picture}
            width={isMobile ? '335px' : '110px'}
            height={isMobile ? '250px' : '146px'}
            loading={isMobile ? 'lazy' : 'eager'}
            className="has-fit-cover has-square-border"
          />
        </figure>
      </div>
      <div className="card-content has-text-centered is-narrow px-1">
        <div className="content">
          <p className={`${titleClassName}`}>{title}</p>
          {subtitle && <p className={`${subtitleClassName}`}>{subtitle}</p>}
        </div>
      </div>
    </div>
  );
}
