import i18next from 'i18next';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { ActionContext, GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import type { MarketplaceKanbanSummary } from '@stimcar/marketplace-libs-common';
import { ensureError, getHttpStatusCode } from '@stimcar/libs-kernel';
import { useActionCallback, useStateIsDefined } from '@stimcar/libs-uikernel';
import { MarketplaceBackendRoutes } from '@stimcar/marketplace-libs-common';
import type { MPStoreDef, StoreState } from '../../store/typings/store.js';
import { KanbanDetails } from './KanbanDetails.js';

/**
 * Retrieves the kanban from the state if we're coming from the main page
 * or download it if the page route is directly invoked (leading to
 * an empty kanbans list in the state).
 *
 * This function doesn't load the attachment so that the details page
 * is shown as soon as possible.
 *
 * The details page itself lazy loads the attachments.
 */
async function initializeStoreAction(
  {
    actionDispatch,
    getState,
    globalActionDispatch,
    httpClient,
  }: ActionContext<MPStoreDef, StoreState>,
  companyId: string,
  siteId: string,
  kanbanId: string
) {
  const selectedKanbanId = `${companyId}/${siteId}/${kanbanId}`;
  const displayedKanbans = getState().cars.kanbanList.kanbans;
  try {
    let selectedKanban = displayedKanbans.find(({ id }) => id === selectedKanbanId);
    if (selectedKanban === undefined) {
      selectedKanban = await httpClient.httpGetAsJson<MarketplaceKanbanSummary>(
        MarketplaceBackendRoutes.KANBAN_SUMMARY(companyId, siteId, kanbanId)
      );
    }
    // Set the kanban in the store
    actionDispatch.setProperty('details', {
      kanban: selectedKanban,
      attachments: [],
    });
    // Replace document title (with the kanban brand / model)
    document.title = `${selectedKanban.brand} ${selectedKanban.model}`;
  } catch (e) {
    const errorCode = getHttpStatusCode(ensureError(e));
    // If the kanban has not been found, show an error indicating
    // that something went wrong, with the given kanban
    if (errorCode === 404) {
      globalActionDispatch.setProperty('error', 'kanbanDetailsNotFound');
    } else {
      // Otherwise propagate the error
      throw e;
    }
  }
}

function clearStoreAction({ actionDispatch }: ActionContext<MPStoreDef, StoreState>) {
  actionDispatch.setProperty('details', undefined);
  // Restore document title
  document.title = i18next.t('marketplace:defaultPageTitle');
}

function KanbanDetailsSkeleton(): JSX.Element {
  const lines = [...Array(15).keys()];
  return (
    <div className="pt-4">
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          <div className="cell is-row-span-2 is-col-span-6">
            <div className="image is-4by3 is-full-height is-skeleton" />
          </div>
          <div className="cell is-col-span-3">
            <div className="image is-4by3 is-full-height is-skeleton" />
          </div>
          <div className="cell is-col-span-3">
            <div className="image is-4by3 is-full-height is-skeleton" />
          </div>
          <div className="cell is-col-span-3">
            <div className="image is-4by3 is-full-height is-skeleton" />
          </div>
          <div className="cell is-col-span-3">
            <div className="image is-4by3 is-full-height is-skeleton" />
          </div>
        </div>
      </div>
      <div className="title is-skeleton my-1">.</div>
      <div className="title has-skeleton my-1">.</div>
      <div className="subtitle has-skeleton my-1">.</div>
      <div className="skeleton-lines py-5">
        {lines.map((line) => (
          <div key={line} />
        ))}
      </div>
    </div>
  );
}

interface Props {
  readonly $gs: GlobalStoreStateSelector<MPStoreDef>;
}

export function KanbanDetailsLoader({ $gs }: Props): JSX.Element {
  const { companyId, siteId, kanbanId } = useParams();

  const initializeStoreActionCallback = useActionCallback(
    initializeStoreAction,
    [companyId, siteId, kanbanId],
    $gs
  );

  const clearStoreActionCallback = useActionCallback(clearStoreAction, [], $gs);

  useEffect((): (() => void) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeStoreActionCallback(companyId!, siteId!, kanbanId!);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return clearStoreActionCallback;
  }, [initializeStoreActionCallback, clearStoreActionCallback, companyId, kanbanId, siteId]);

  const kanbanIsLoaded = useStateIsDefined($gs.$details);

  if (!kanbanIsLoaded) {
    return <KanbanDetailsSkeleton />;
  }

  return <KanbanDetails $gs={$gs} />;
}
