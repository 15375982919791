import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../App.js';
import './HorizontalCard.scss';
import { Image } from './image/Image.js';

interface HorizontalCardProps extends MarketplaceAppProps {
  readonly alt: string;
  readonly image: string;
  readonly title: string;
  readonly subtitle?: string;
  readonly content: ReactNode;
}

export function HorizontalCard({
  $gs,
  alt,
  title,
  image,
  content,
  subtitle,
}: HorizontalCardProps): JSX.Element {
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  return (
    <div
      className={`horizontal-card-container ${isMobile ? 'mb-5' : 'm-3'} box has-${bulmaTheme}-background p-0 has-square-border`}
    >
      <div className="columns">
        {!isMobile && (
          <div className="p-0">
            <Image width="200px" height="250px" src={image} alt={alt} className="left-image" />
          </div>
        )}
        <div className="column px-5">
          <div className="mb-2">
            <span className="title is-5 has-background-primary-gradient has-text-white">
              {title}
            </span>
          </div>
          {subtitle && <div className="title is-6 mb-1">{subtitle}</div>}
          <div className="content is-size-6-and-half">{content}</div>
        </div>
      </div>
    </div>
  );
}
