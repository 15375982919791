import { t } from 'i18next';
import type {
  DefectWithSelectedRefurbishingOptions,
  PriceableDefect,
  PriceableRefurbishingOption,
} from '@stimcar/marketplace-libs-common';
import { isTruthy } from '@stimcar/libs-kernel';
import {
  KEEP_AS_IT_IS_REFURBISHING_OPTION_ID,
  KEEP_AS_IT_IS_REFURBISHING_OPTION_PRICE,
} from '@stimcar/marketplace-libs-common';

function indexAscSort(
  { index: indexA }: { index: number },
  { index: indexB }: { index: number }
): number {
  return indexA - indexB;
}

export function getSortedDefectsWithFullSortedRefurbishingOptions(
  defects: readonly PriceableDefect[]
): readonly PriceableDefect[] {
  return defects
    .map((defect) => ({
      ...defect,
      refurbishingOptions: [
        {
          index: -1,
          packageDealId: '',
          label: t('marketplace:defects.keepAsItIs'),
          id: KEEP_AS_IT_IS_REFURBISHING_OPTION_ID,
          price: KEEP_AS_IT_IS_REFURBISHING_OPTION_PRICE,
        },
        ...defect.refurbishingOptions,
      ].sort(indexAscSort),
    }))
    .sort(indexAscSort);
}

function getDefectPrice(
  refurbishingOptions: readonly PriceableRefurbishingOption[],
  selectedRefurbishingOptionId: string
): number {
  const selectedRefurbishingOption = refurbishingOptions.find(
    ({ id }) => id === selectedRefurbishingOptionId
  );
  if (isTruthy(selectedRefurbishingOption)) {
    return selectedRefurbishingOption.price;
  }
  return 0;
}

export function getTotalRefurbishingPrice(
  defects: readonly PriceableDefect[],
  defectsWithSelectedRefurbishingOptions: readonly DefectWithSelectedRefurbishingOptions[]
): number {
  return defectsWithSelectedRefurbishingOptions.reduce(
    (total, { id, selectedRefurbishingOptionId }) => {
      const defect = defects.find(({ id: defectId }) => defectId === id);
      let defectPrice = 0;
      if (isTruthy(defect)) {
        defectPrice = getDefectPrice(defect.refurbishingOptions, selectedRefurbishingOptionId);
      }

      return total + defectPrice;
    },
    0
  );
}
