import type { CarsSort } from '@stimcar/marketplace-libs-common';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { CARS_SORTS } from '@stimcar/marketplace-libs-common';
import type { KanbanFiltersState } from '../../../store/typings/store.js';
import { EMPTY_KANBAN_FILTERS_STATE } from '../../../store/store.js';

const SEARCH_PARAMS_VALUES_SEPARATOR = '+';

export const PAGE_SEARCH_PARAM_KEY = 'page';
export const SORT_SEARCH_PARAM_KEY = 'sort';

type FiltersSearchParamKeys =
  | 'search'
  | 'brand'
  | 'fuel'
  | 'gearbox'
  | 'minYear'
  | 'maxYear'
  | 'minMileage'
  | 'maxMileage'
  | 'minPrice'
  | 'maxPrice';

type SearchParamKeys =
  | FiltersSearchParamKeys
  | typeof PAGE_SEARCH_PARAM_KEY
  | typeof SORT_SEARCH_PARAM_KEY;

function getNumberFromSearchParamOrDefaultValue<T>(
  searchParam: string | null,
  defaultValue: T
): number | T {
  const value = Number(searchParam);
  if (!Number.isNaN(value)) {
    return value;
  }
  return defaultValue;
}

export function clearSearchParamsFromFilters(
  previousSearchParams: URLSearchParams
): Record<SearchParamKeys, string> {
  const clearedSearchParams: Record<string, string> = {};
  const sortSearchParam = previousSearchParams.get(SORT_SEARCH_PARAM_KEY);

  if (isTruthyAndNotEmpty(sortSearchParam)) {
    clearedSearchParams[SORT_SEARCH_PARAM_KEY] = sortSearchParam;
  }

  return clearedSearchParams;
}

export function getFullSearchParams(
  previousSearchParams: URLSearchParams,
  filtersSearchParams: Record<FiltersSearchParamKeys, string>
): Record<SearchParamKeys, string> {
  const sort = previousSearchParams.get(SORT_SEARCH_PARAM_KEY);

  const fullSearchParams = filtersSearchParams as Record<SearchParamKeys, string>;

  if (isTruthyAndNotEmpty(sort)) {
    fullSearchParams[SORT_SEARCH_PARAM_KEY] = sort;
  }

  return fullSearchParams;
}

export function getCurrentPageFromSearchParam(pageSearchParam: string | null): number {
  const defaultPage = 1;
  const currentPage = getNumberFromSearchParamOrDefaultValue(pageSearchParam, defaultPage);
  if (currentPage <= 0) {
    return defaultPage;
  }
  return currentPage;
}

export function getSortPageFromSearchParam(sortSearchParam: string | null): CarsSort {
  if (isTruthyAndNotEmpty(sortSearchParam) && CARS_SORTS.includes(sortSearchParam as CarsSort)) {
    return sortSearchParam as CarsSort;
  }
  return '';
}

export function convertFiltersToUrlSearchParams(
  filters: KanbanFiltersState
): Record<FiltersSearchParamKeys, string> {
  const {
    brandFilter,
    fuelFilter,
    gearboxFilter,
    mileageFilter,
    priceFilter,
    textFilter,
    yearFilter,
  } = filters;
  const searchParams = {} as Record<FiltersSearchParamKeys, string>;

  if (brandFilter.selectedValues.length > 0) {
    searchParams.brand = brandFilter.selectedValues.join(SEARCH_PARAMS_VALUES_SEPARATOR);
  }

  if (fuelFilter.selectedValues.length > 0) {
    searchParams.fuel = fuelFilter.selectedValues.join(SEARCH_PARAMS_VALUES_SEPARATOR);
  }

  if (gearboxFilter.selectedValues.length > 0) {
    searchParams.gearbox = gearboxFilter.selectedValues.join(SEARCH_PARAMS_VALUES_SEPARATOR);
  }

  if (yearFilter.minValue) {
    searchParams.minYear = yearFilter.minValue.toString();
  }

  if (yearFilter.maxValue) {
    searchParams.maxYear = yearFilter.maxValue.toString();
  }

  if (mileageFilter.minValue) {
    searchParams.minMileage = mileageFilter.minValue.toString();
  }

  if (mileageFilter.maxValue) {
    searchParams.maxMileage = mileageFilter.maxValue.toString();
  }

  if (priceFilter.minValue) {
    searchParams.minPrice = priceFilter.minValue.toString();
  }

  if (priceFilter.maxValue) {
    searchParams.maxPrice = priceFilter.maxValue.toString();
  }

  if (isTruthyAndNotEmpty(textFilter)) {
    searchParams.search = textFilter;
  }

  return searchParams;
}

export function getUpdatedFiltersFromSearchParams(
  { brandFilter, fuelFilter, gearboxFilter }: KanbanFiltersState,
  searchParams: URLSearchParams
): KanbanFiltersState {
  const {
    search,
    brand,
    fuel,
    gearbox,
    minYear,
    maxYear,
    minMileage,
    maxMileage,
    minPrice,
    maxPrice,
  } = Object.fromEntries(searchParams) as Record<FiltersSearchParamKeys, string>;
  return {
    textFilter: isTruthyAndNotEmpty(search) ? search : EMPTY_KANBAN_FILTERS_STATE.textFilter,
    brandFilter: {
      ...brandFilter,
      selectedValues: isTruthyAndNotEmpty(brand)
        ? brand.split(SEARCH_PARAMS_VALUES_SEPARATOR)
        : EMPTY_KANBAN_FILTERS_STATE.brandFilter.selectedValues,
    },
    fuelFilter: {
      ...fuelFilter,
      selectedValues: isTruthyAndNotEmpty(fuel)
        ? fuel.split(SEARCH_PARAMS_VALUES_SEPARATOR)
        : EMPTY_KANBAN_FILTERS_STATE.fuelFilter.selectedValues,
    },
    gearboxFilter: {
      ...gearboxFilter,
      selectedValues: isTruthyAndNotEmpty(gearbox)
        ? gearbox.split(SEARCH_PARAMS_VALUES_SEPARATOR)
        : EMPTY_KANBAN_FILTERS_STATE.gearboxFilter.selectedValues,
    },
    yearFilter: {
      minValue: getNumberFromSearchParamOrDefaultValue(
        minYear,
        EMPTY_KANBAN_FILTERS_STATE.yearFilter.minValue
      ),
      maxValue: getNumberFromSearchParamOrDefaultValue(
        maxYear,
        EMPTY_KANBAN_FILTERS_STATE.yearFilter.maxValue
      ),
    },
    mileageFilter: {
      minValue: getNumberFromSearchParamOrDefaultValue(
        minMileage,
        EMPTY_KANBAN_FILTERS_STATE.mileageFilter.minValue
      ),
      maxValue: getNumberFromSearchParamOrDefaultValue(
        maxMileage,
        EMPTY_KANBAN_FILTERS_STATE.mileageFilter.maxValue
      ),
    },
    priceFilter: {
      minValue: getNumberFromSearchParamOrDefaultValue(
        minPrice,
        EMPTY_KANBAN_FILTERS_STATE.priceFilter.minValue
      ),
      maxValue: getNumberFromSearchParamOrDefaultValue(
        maxPrice,
        EMPTY_KANBAN_FILTERS_STATE.priceFilter.maxValue
      ),
    },
  };
}
