import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenWidthLessThan } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../App.js';
import type { CardContent } from '../utils/contact-and-cards/ContactAndCards.js';
import { ContactButton } from '../contact/ContactButton.js';
import { ContactAndCards } from '../utils/contact-and-cards/ContactAndCards.js';
import { ExperienceSection } from '../utils/experience/ExperienceSection.js';
import { HeadSection } from '../utils/head-section/HeadSection.js';
import { HorizontalCard } from '../utils/HorizontalCard.js';

const CARD_THEMES: readonly string[] = ['carBody', 'paint', 'mechanic', 'options'];
const BENEFITS: readonly string[] = [
  'costReduction',
  'quality',
  'customisation',
  'betterProcess',
  'easySell',
  'guarantee',
];
const MODE = 'refresh';

type ContactAndRefreshCardsProps = MarketplaceAppProps;

function ContactAndRefreshCards({ $gs }: ContactAndRefreshCardsProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const cards: readonly CardContent[] = useMemo(
    () =>
      CARD_THEMES.map((theme) => ({
        picture: `/img/refresh/${theme}.webp`,
        alt: t(`refresh.${theme}`),
        title: t(`refresh.${theme}`),
      })),
    [t]
  );

  return (
    <ContactAndCards
      $gs={$gs}
      cards={cards}
      cardDesktopWidth={140}
      contactReason="refresh"
      contactSubtitle={t(`refresh.ourExpertsRefreshYourCar`)}
      contactTitle={t('refresh.newAtUsedPrice')}
      contactButtonLabel={t('refresh.makeAnAppointment')}
    />
  );
}

type RefreshBenefitsSectionProps = MarketplaceAppProps;

function RefreshBenefitsSection({ $gs }: RefreshBenefitsSectionProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const isSmallDevice = useScreenWidthLessThan($gs.$window, 1215);
  return (
    <section className="services-section fixed-grid has-1-cols has-2-cols-desktop pl-3 mb-0">
      <div className={`grid ${isSmallDevice ? 'small-device-grid' : ''}`}>
        {BENEFITS.map((benefit) => (
          <div className="cell" key={benefit}>
            <HorizontalCard
              $gs={$gs}
              image={`/img/refresh/${benefit}.webp`}
              alt={t(`refresh.benefits.${benefit}.alt`)}
              title={t(`refresh.benefits.${benefit}.title`)}
              subtitle={t(`refresh.benefits.${benefit}.subtitle`)}
              content={<p>{t(`refresh.benefits.${benefit}.content`)}</p>}
            />
          </div>
        ))}
      </div>
    </section>
  );
}

type Props = MarketplaceAppProps;

export function RefreshContent({ $gs }: Props): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <>
      <HeadSection $gs={$gs} mode={MODE} />
      <ContactAndRefreshCards $gs={$gs} />
      <section className="has-text-centered py-2">
        <ContactButton
          $gs={$gs}
          reason="refresh"
          label={t('refresh.contactCenter')}
          className="stimcar-gradient-button is-preline"
        />
      </section>
      <section className="mb-5 py-5">
        <h4 className="title is-4 has-text-centered">{t('refresh.whyRefresh')}</h4>
      </section>
      <RefreshBenefitsSection $gs={$gs} />
      <ExperienceSection $gs={$gs} mode={MODE} hasNoBottomImage alt="" />
    </>
  );
}
