import React, { useEffect, useMemo } from 'react';
import type { ActionContext, LeafStateSelector, ObjectStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import { getKanbanDetailsLabel, MarketplaceBackendRoutes } from '@stimcar/marketplace-libs-common';
import type { KanbanDetailsState, MPStoreDef } from '../../store/typings/store.js';
import type { MarketplaceAppProps } from '../App.js';
import type { PictureKey } from '../utils/DetailPicture.js';
import { DetailPicture } from '../utils/DetailPicture.js';
import { formatNumberWithSpaces } from '../utils/format/formatUtils.js';
import { openImageModaleAction } from '../utils/ImageModalDialog.js';

async function initializeStoreAction(
  { actionDispatch, httpClient }: ActionContext<MPStoreDef, KanbanDetailsState>,
  companyId: string,
  siteId: string,
  kanbanId: string
): Promise<void> {
  const attachments = await httpClient.httpGetAsJson<readonly string[]>(
    MarketplaceBackendRoutes.LIST_ATTACHMENTS(companyId, siteId, kanbanId)
  );
  actionDispatch.setProperty('attachments', attachments.slice().sort());
}

async function onDetailImageClickAction(
  { globalActionDispatch, getState }: ActionContext<MPStoreDef, KanbanDetailsState>,
  { companyId, siteId, kanbanId, filename }: PictureKey
) {
  await globalActionDispatch.scopeProperty('imageModalDialog').exec(
    openImageModaleAction,
    getState().attachments.map((itemFilename) =>
      MarketplaceBackendRoutes.ATTACHMENT(companyId, siteId, kanbanId, itemFilename)
    ),
    MarketplaceBackendRoutes.ATTACHMENT(companyId, siteId, kanbanId, filename)
  );
}

type Props = MarketplaceAppProps;

export function KanbanDetails({ $gs }: Props): JSX.Element {
  const $ = $gs.$details as LeafStateSelector<MPStoreDef, KanbanDetailsState> &
    ObjectStateSelector<MPStoreDef, KanbanDetailsState>;
  const { $kanban } = $;
  const id = useGetState($kanban.$id);
  const [companyId, siteId, kanbanId] = useMemo(() => id.split('/'), [id]);
  const kanban = useGetState($kanban);
  const label = getKanbanDetailsLabel(kanban);
  const mileage = useGetState($kanban.$mileage);
  const dateOfRegistration = useGetState($kanban.$dateOfRegistration);
  const publicPrice = useGetState($kanban.$publicPrice);
  const status = useGetState($kanban.$status);
  const mainPicture = useGetState($kanban.$pictures.$main);
  const detailPicture1 = useGetState($kanban.$pictures.$detail1);
  const detailPicture2 = useGetState($kanban.$pictures.$detail2);
  const detailPicture3 = useGetState($kanban.$pictures.$detail3);
  const detailPicture4 = useGetState($kanban.$pictures.$detail4);
  const adHtmlText = useGetState($kanban.$adHtmlText);
  const location = useGetState($kanban.$location);
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const initializeStoreActionCallback = useActionCallback(
    initializeStoreAction,
    [companyId, siteId, kanbanId],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeStoreActionCallback(companyId, siteId, kanbanId);
  }, [companyId, initializeStoreActionCallback, kanbanId, siteId]);

  // The date of registration may be unset (null or undefined)
  const year = useMemo(
    () => (dateOfRegistration ? new Date(dateOfRegistration).getFullYear() : undefined),
    [dateOfRegistration]
  );

  const onDetailImageClickActionCallback = useActionCallback(onDetailImageClickAction, [], $);

  return (
    <div className="pt-4">
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          <div className="cell is-row-span-2 is-col-span-6">
            <DetailPicture
              loading="eager"
              siteId={siteId}
              kanbanId={kanbanId}
              companyId={companyId}
              filename={mainPicture}
              marketplaceStatus={status}
              width={isMobile ? '173px' : '574px'}
              height={isMobile ? '130px' : '430px'}
              onClick={onDetailImageClickActionCallback}
            />
          </div>
          <div className="cell is-col-span-3">
            <DetailPicture
              loading="eager"
              companyId={companyId}
              siteId={siteId}
              kanbanId={kanbanId}
              filename={detailPicture1}
              marketplaceStatus={status}
              width={isMobile ? '79px' : '279px'}
              height={isMobile ? '59px' : '210px'}
              onClick={onDetailImageClickActionCallback}
            />
          </div>
          <div className="cell is-col-span-3">
            <DetailPicture
              loading="eager"
              companyId={companyId}
              siteId={siteId}
              kanbanId={kanbanId}
              filename={detailPicture2}
              marketplaceStatus={status}
              width={isMobile ? '79px' : '279px'}
              height={isMobile ? '59px' : '210px'}
              onClick={onDetailImageClickActionCallback}
            />
          </div>
          <div className="cell is-col-span-3">
            <DetailPicture
              loading="eager"
              companyId={companyId}
              siteId={siteId}
              kanbanId={kanbanId}
              filename={detailPicture3}
              marketplaceStatus={status}
              width={isMobile ? '79px' : '279px'}
              height={isMobile ? '59px' : '210px'}
              onClick={onDetailImageClickActionCallback}
            />
          </div>
          <div className="cell is-col-span-3">
            <DetailPicture
              loading="eager"
              companyId={companyId}
              siteId={siteId}
              kanbanId={kanbanId}
              filename={detailPicture4}
              marketplaceStatus={status}
              width={isMobile ? '79px' : '279px'}
              height={isMobile ? '59px' : '210px'}
              onClick={onDetailImageClickActionCallback}
            />
          </div>
        </div>
      </div>
      <div className="title is-3">{label}</div>
      <div className="subtitle is-5">{`${year ? `${year} - ` : ''}${mileage} km - ${location}`}</div>
      <h5 className="title is-5 stimcar-gradient-button my-3 py-1 px-3">{`${formatNumberWithSpaces(publicPrice)} €`}</h5>
      <div className="content">
        {/*  eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: adHtmlText }} />
      </div>
    </div>
  );
}
