import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { MPStoreDef, RangePickerState } from '../../../store/typings/store.js';

function getValueWithLimit(value: number, minLimit?: number, maxLimit?: number): number {
  if (!isTruthy(minLimit) && !isTruthy(maxLimit)) {
    return value;
  }
  let valueWithLimit: number = value;
  if (isTruthy(minLimit)) {
    valueWithLimit = Math.max(minLimit, valueWithLimit);
  }
  if (isTruthy(maxLimit)) {
    valueWithLimit = Math.min(maxLimit, valueWithLimit);
  }
  return valueWithLimit;
}

interface RangePickerProps {
  readonly $: StoreStateSelector<MPStoreDef, RangePickerState>;
  readonly minLabel?: string;
  readonly maxLabel?: string;
  readonly minValuePlaceholder?: string;
  readonly maxValuePlaceholder?: string;
  readonly minLimit?: number;
  readonly maxLimit?: number;
}

export function RangePicker({
  $,
  minLabel,
  maxLabel,
  minValuePlaceholder,
  maxValuePlaceholder,
  minLimit,
  maxLimit,
}: RangePickerProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const minValue = useGetState($.$minValue);
  const maxValue = useGetState($.$maxValue);

  const onMinChangeActionCallback = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isTruthyAndNotEmpty(e.target.value)) {
        actionDispatch.setValue(undefined);
      } else {
        const valueAsNumber = Number(e.target.value);
        const value = getValueWithLimit(valueAsNumber, minLimit, maxLimit);
        actionDispatch.setValue(value);
      }
    },
    [minLimit, maxLimit],
    $.$minValue
  );
  const onMaxChangeActionCallback = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isTruthyAndNotEmpty(e.target.value)) {
        actionDispatch.setValue(undefined);
      } else {
        const valueAsNumber = Number(e.target.value);
        const value = getValueWithLimit(valueAsNumber, minLimit, maxLimit);
        actionDispatch.setValue(value);
      }
    },
    [minLimit, maxLimit],
    $.$maxValue
  );

  return (
    <div className="field control">
      <h3 className="is-size-7">{minLabel ?? t('filters.defaultMinPlaceholder')}</h3>
      <input
        name="minInput"
        type="number"
        className="input is-rounded is-small"
        placeholder={minValuePlaceholder}
        min={minLimit}
        max={maxLimit}
        value={minValue ?? ''}
        onChange={onMinChangeActionCallback}
      />

      <h3 className="is-size-7">{maxLabel ?? t('filters.defaultMaxPlaceholder')}</h3>
      <input
        name="maxInput"
        type="number"
        className="input is-rounded is-small"
        placeholder={maxValuePlaceholder}
        min={minValue}
        max={maxLimit}
        value={maxValue ?? ''}
        onChange={onMaxChangeActionCallback}
      />
    </div>
  );
}
